@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.user-avatar {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 100%;
  background: {
    repeat: no-repeat;
    position: center;
    color: $color-grey-5;
  }

  &__placeholder {
    background: {
      image: url("../../../assets/icons/user-dark-grey.svg");
      size: 50%;
    }

    &--farm {
      background: {
        image: url("../../../assets/icons/farm-dark-grey.svg");
        size: 60%;
      }
    }
  }

  &__image {
    background : {
      size: cover;
    }
  }
}
