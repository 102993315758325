@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.active-farms {
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 15px;

  @media (max-width: 415px) {
    padding: 10px;
  }

  &__farms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: bold;
  }
  &__subtitle {
    font-size: 14px;
    text-decoration: none;
    color: $color-grey-3;
  }
}

.active-farm-item {
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__image-wrapper {
    height: 76px;
    width: 76px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    background-color: #f2f2f2;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: {
      image: url("../../../assets/icons/farm-light-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 50%;
      color: $color-grey-5;
    }
  }

  &__details {
    padding-top: 10px;
    cursor: default;
  }

  &__name {
    @include type-basic;
    color: $color-grey;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    @media (max-width: 415px) {
      font-size: 12px;
    }
  }
}
