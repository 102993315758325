@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.loader {
  width: 100%;
  margin-top: $space-small;
  text-align: center;

  &__icon {
    height: 30px;
    width: 30px;
    margin: 4px 2px;

    opacity: 0.8;
    animation: pulse 0.6s alternate infinite ease-in-out;

    &--right {
      animation-delay: 0.6s;
    }
  }

  &__load-message {
    @include type-basic;
    margin: 0 0 $space-small 0;
  }

  @keyframes pulse {
    to {
      opacity: 0.5;
      transform: scale(0.8);
    }
  }
}
