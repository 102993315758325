@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.congratulations {
  max-width: 1110px;
  min-height: calc(100vh - 104px);
  margin: 0 auto;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include bp-large {
    height: 675px;
    align-items: center;
    margin-top: $space-large;
    margin-bottom: 10px;
    padding-top: 0;
    min-height: auto;
    background-color: $color-white;
    border: 1px solid $color-grey-5;
  }
  &__wrapper {
    text-align: center;
    padding: 0 $space-small;
    width: 100%;

    @include bp-large {
      width: 400px;
    }
  }

  &__icon {
    width: 120px;
  }

  &__heading {
    @include type-extra-large;
  }

  &__sub-heading {
    @include type-large;
    color: $color-grey-3;
  }

  &__button-sign-in {
    margin: $space-big $space-small 0 $space-small;
    @include bp-large {
      display: none;
    }
  }
}
