.promo-logos-wrapper {
  margin-top: 30px;

  @media (min-width: 992px) {
    width: 1109px;
    margin: 50px auto 0 auto;
  }

  .promo-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: all 0.3s ease-out;

      max-width: 150px;

      filter: grayscale(100%);

      &:hover {
        cursor: pointer;
        filter: grayscale(0);
      }
    }
  }
}
