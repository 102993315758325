@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.register-bottom {
  margin-top: 30px;

  @media (min-width: 992px) {
    width: 1109px;
    margin: 50px auto 0 auto;
    border: 1px solid #e5e5e5;
  }

  .register-video {
    .link-to-infopage {
      background-color: $color-green-4;

      a {
        display: block;
        text-align: left;
        padding: 15px 30px;
        color: white;
        text-decoration: none;
        transition: all 0.3s ease-out;

        span {
          float: right;
        }
        &:hover {
          background-color: darken($color-green-4, 5%);
        }
      }
    }
    #promo-video {
      width: 100%;
      height: 600px;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}
