@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-details-modal {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include bp-large {
    width: 90vw;
    height: 90vh;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  &__media-container {
    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        width: calc(100% - 500px);
        height: 100%;
        display: block;
        background-color: $color-black;
      }
    }
  }

  &__details-container {
    @include bp-large {
      width: 500px;
      overflow: hidden;
      border-left: 2px solid $color-grey-5;
    }
  }

  &__header {
    height: 60px;
    padding-left: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
  }

  &__header-details {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__author {
    @include type-basic;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: bold;
    color: $color-green-2;
  }

  &__text {
    @include type-basic;
    padding: $space-small;

    @include bp-large {
      height: 100px;
      overflow-y: scroll;
    }
  }

  &__close {
    @include bp-large {
      align-self: flex-start;
    }
  }

  &__details-scroll {
    height: calc(100vh - 170px);
    padding-bottom: 100px;
    overflow-y: auto;
    overflow-x: hidden;

    @include bp-large {
      height: auto;
      padding-bottom: 0;
      overflow: hidden;
    }
  }

  &__actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $color-white;

    @include bp-large {
      position: static;
    }
  }

  &__comments {
    display: none;

    @include bp-large {
      display: block;
    }
  }
}
