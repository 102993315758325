@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.sell-article-modal {
  width: 100%;
  height: 100%;
  position: relative;
  background: $color-white;

  &__image-placeholder {
    position: relative;
    width: 170px;
    height: 170px;
  }

  &__loader-wrapper {
    position: absolute;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-grey-4;
    background: white;
    z-index: 2;
  }

  @include bp-large {
    width: 850px;
    height: 720px;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    padding: $space-small $space-small 80px;
    overflow-y: scroll;

    @include bp-large {
      padding: 0;
      overflow: hidden;
      padding: $space-medium $space-medium 0;
    }
  }

  &__heading {
    @include type-extra-large;
    width: 100%;
    margin: 0 0 $space-medium;
    font-weight: bold;

    @include bp-large {
      margin-bottom: $space-big;
    }
  }

  &__row {
    margin-bottom: $space-medium;

    &--inline {
      display: flex;
      align-items: center;
    }

    &--center {
      input {
        width: 100%;
        text-align: center;
      }
    }

    &.price-row {
      .on-request-label {
        margin-left: 10px;
        display: flex;
        align-items: center;

        .input {
          height: auto;
          display: inline-block;
        }

        input {
          width: auto;
          margin-right: 5px;
        }
      }

      input[type="text"] {
        width: 180px;

        &:read-only {
          background-color: $color-grey-6;
        }
      }
    }
  }

  &__form-label {
    width: 85px;
    margin: 0;
    font-size: 0.875rem;
    flex-shrink: 0;
    font-weight: bold;
    &.short {
      width: auto;
      margin-right: 5px;
    }
  }

  &__buttons {
    width: 100%;
    padding: 10px $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small $space-medium $space-medium;
    }
  }

  &__form {
    width: 100%;

    @include bp-large {
      display: flex;
    }
  }

  &__form-content {
    margin-bottom: $space-large;

    @include bp-large {
      width: 370px;
      margin-right: $space-large;
    }
  }

  &__submit-button-container {
    position: relative;
  }

  &__error {
    @include type-small;
    margin: $space-small 0;
    color: $color-red;

    @include bp-large {
      width: 180px;
    }

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__farm {
    margin-bottom: $space-large;

    @include bp-large {
      margin-bottom: 75px;
    }
  }

  &__farm-buttons {
    display: flex;
  }

  &__join-button {
    margin-right: $space-small;
  }

  &__delete-picture-button {
    margin-top: $space-extra-small;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    @include bp-large {
      width: 370px;
      height: 560px;
      padding-bottom: $space-medium;
      overflow-y: auto;
    }
  }

  &__image {
    width: 170px;
    height: 170px;
    margin: 0 $space-medium $space-medium 0;
    display: inline-block;
    position: relative;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    @include bp-large {
      margin-right: 0;
      &:nth-child(odd) {
        margin-right: $space-medium;
      }
    }
  }

  &__delete-image {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    background: {
      image: url("../../../assets/icons/delete.svg");
      repeat: no-repeat;
      size: 12px;
      position: center;
    }
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
  }

  &__label {
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-grey-4;
    cursor: pointer;
    position: absolute;
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label-text {
    @include type-small;
    padding-top: 20px;
    color: $color-grey-3;
    font-weight: bold;
    background: {
      image: url("../../../assets/icons/image-light-grey.svg");
      repeat: no-repeat;
      size: 20px;
      position: center 0;
    }
  }

  &__input {
    display: none;
  }

  &__error {
    @include type-small;
    width: 100%;
    margin: $space-extra-small 0;
    color: $color-red;

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }
}
