@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.create-post-modal {
  @keyframes showTextPostBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }

  &__outer-container {
    display: none;

    @include bp-large {
      display: block;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 15;
      background: $color-black;
      opacity: 0.6;
      animation: showTextPostBackground 100ms ease-in;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background: $color-white;

    @include bp-large {
      width: 640px;
      height: auto;
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
      border: 1px solid $color-grey-5;
      background: $color-white;
    }

    @media (max-width: 991px) {
      height: auto;
      top: 61px;
      bottom: 50px;
    }

    @include bp-extra-large {
      left: calc(50% - 47px);
    }
  }

  &__header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;

    &--desktop {
      display: none;

      @include bp-large {
        display: flex;
      }
    }

    &--mobile {
      padding: 0 0 0 $space-small;

      @include bp-large {
        display: none;
      }

      span {
        @include type-small;
        font-weight: bold;
        color: $color-grey-4;
      }
    }
  }

  &__scroll-container {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding-bottom: 70px;
    margin-top: 39px;

    @include bp-large {
      max-height: calc(100vh - 290px);
      padding-bottom: 0;
    }
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__header-create {
    height: 20px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    background: {
      image: url("../../../assets/icons/post-light-grey.svg");
      repeat: no-repeat;
      size: 14px;
    }

    &--image {
      padding-left: 30px;
      background: {
        image: url("../../../assets/icons/image-light-grey.svg");
        size: 20px;
        position: 0 center;
      }
    }

    &--video {
      padding-left: 30px;
      background: {
        image: url("../../../assets/icons/video-light-grey.svg");
        size: 20px;
      }
    }
  }

  &__text-container {
    height: calc(100% - 170px);
    padding: $space-small;
    display: flex;

    @include bp-large {
      height: auto;
    }

    &--small {
      padding-bottom: 0;
    }
  }

  &__textarea-container {
    width: 100%;

    &--mobile {
      display: block;

      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__textarea {
    @include type-large;
    width: 100%;
    padding: 5px 0 0;
    resize: none;
    outline: none;
    border: none;
    color: $color-grey;

    &--small {
      @include type-basic;
      padding: 0;
      line-height: 30px;
    }
  }

  &__counter {
    @include type-small;
    margin: 0;
    text-align: right;
    padding-right: $space-small;
    color: $color-grey-3;

    &--mobile-hidden {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__image-container {
    margin-top: $space-small;
    position: relative;
  }

  &__image {
    width: 100%;
    display: block;
  }

  &__remove-button {
    width: 15px;
    height: 20px;
    background: {
      image: url("../../../assets/icons/delete.svg");
      repeat: no-repeat;
      position: center;
    }
    position: absolute;
    top: 15px;
    right: 15px;
    box-shadow: 2px 2px 4px 0px rgba($color-black, 0.4);
    border-radius: 2px;
  }

  &__publish-container {
    padding: $space-small;
    background: $color-white;

    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__footer-container {
    width: 100%;
    position: absolute;
    bottom: 0;

    @include bp-large {
      display: none;
    }
  }

  &__footer {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $color-grey-5;
    border-bottom: 1px solid $color-grey-5;
    position: fixed;
    top: 100px;
    width: 100%;

    @include bp-large {
      display: none;
    }
  }

  &__video-container {
    margin-top: $space-small;
    position: relative;
    background: {
      image: url("../../../assets/icons/video-white.svg");
      repeat: no-repeat;
      size: 140px;
      position: center;
      color: $color-grey-4;
    }

    &--fixed {
      height: 300px;
    }
  }

  &__preview {
    width: 100%;
    display: block;
  }

  &__duration {
    @include type-small;
    padding: 10px 10px 10px 50px;
    position: absolute;
    bottom: $space-small;
    left: $space-small;
    color: $color-white;
    background: {
      image: url("../../../assets/icons/video-white.svg");
      repeat: no-repeat;
      size: 20px;
      position: 15px 7px;
      color: $color-black;
    }
  }

  &__error {
    @include type-small;
    margin: $space-small 0;
    padding-left: $space-small;
    color: $color-red;
  }

  .news-remote-url {
    padding: 0 15px;

    input {
      width: 100%;
      height: 40px;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.circle-loader-wrapper {
  width: 100%;
  height: 125px;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 70px;
  position: relative;

  @media (max-width: 991px) {
    margin-top: 45px;
    margin-bottom: 0;
  }

  .CircularProgressbar {
    width: 125px;
    margin: 0 auto;
  }

  .loader {
    margin-top: -83px;
  }
}

.circle-loader-wrapper.small-circle {
  width: 100%;
  height: 70px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;

  @media (max-width: 991px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .CircularProgressbar {
    width: 70px;
    margin: 0 auto;
  }

  .loader {
    margin-top: -48px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
