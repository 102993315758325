@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.profile-image {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 110px;
    height: 110px;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid $color-grey-5;
    border-radius: 100%;
    background: $color-white;
  }

  &__image {
    width: 102px;
    height: 102px;
    position: relative;
    border: 1px solid $color-grey-5;
    border-radius: 100%;
    background: {
      image: url("../../../assets/icons/farm-dark-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 22px;
      color: $color-grey-6;
    }
    outline: none;

    &--user {
      background-image: url("../../../assets/icons/user-dark-grey.svg");
    }

    &--active {
      cursor: pointer;
    }
  }
}
