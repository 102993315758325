@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.create-post {
  border-radius: 2px;
  border-top: 1px solid $color-grey-5;
  border-bottom: 1px solid $color-grey-5;
  background: $color-white;

  @media (max-width: 991px) {
    margin-top: 0;
  }

  @include bp-large {
    border: 1px solid $color-grey-5;
  }

  &__header {
    display: none;

    @include bp-large {
      display: block;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-grey-5;
    }
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__text-container {
    padding: 10px $space-small 10px;
    display: flex;

    @include bp-large {
      padding: $space-small $space-small 20px;
    }
  }

  &__textarea {
    @include type-basic;
    width: 100%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    resize: none;
    border: none;
    outline: none;
    overflow: hidden;
    color: $color-grey;
  }
}
