@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.search-results-map {
  max-width: 640px;
  margin: 0 auto;
  background: $color-white;
  border: 1px solid $color-grey-5;

  .maps-wrapper {
    width: 100%;
    height: 200px;
  }

  .maps {
    &__header {
      display: none;

      @include bp-large {
        display: flex;
        height: $space-big;
        align-items: center;
        border-bottom: 1px solid $color-grey-5;
      }
    }

    &__header-icon-wrapper {
      height: 100%;
      padding: 11px 10px 11px $space-small;
    }

    &__header-icon {
      height: 100%;
    }

    &__header-heading {
      @include type-small;
      margin: 0;
      color: $color-grey-2;
    }
  }
}

.search-results {
  display: flex;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
    .farms-list {
      width: 100%;
    }
  }
}

.farms-list {
  max-width: 640px;
  margin: 0 auto;
  background: $color-white;
  border: 1px solid $color-grey-5;

  @include bp-large {
    width: 640px;
  }

  &__item {
    margin: 0 $space-small;
    border-bottom: 1px solid $color-grey-5;

    &:last-child {
      border-bottom: none;
    }
  }

  &__header {
    display: none;

    @include bp-large {
      display: flex;
      height: $space-big;
      align-items: center;
      border-bottom: 1px solid $color-grey-5;
    }
  }

  &__header-icon-wrapper {
    height: 100%;
    padding: 11px 10px 11px $space-small;
  }

  &__header-icon {
    height: 100%;
  }

  &__header-heading {
    @include type-small;
    margin: 0;
    color: $color-grey-3;
  }
}
