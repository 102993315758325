@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.membership-requests-modal {
  width: 100vw;
  height: 100vh;
  background: $color-white;

  @include bp-large {
    width: 640px;
    height: 360px;
    border-radius: 2px;
    overflow: hidden;
  }

  &__header {
    height: $space-big;
    padding-left: $space-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-5;
  }

  &__requests-number {
    @include type-small;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: $space-medium;
    color: $color-grey-3;
    background: {
      image: url("../../../assets/icons/members.svg");
      repeat: no-repeat;
      position: 0 center;
      size: 22px;
    }
  }

  &__list {
    height: calc(100vh - #{$space-big});
    padding-left: 20px;
    overflow-y: auto;

    @include bp-large {
      height: 320px;
      margin-right: 10px;
      padding: 0 20px 0 $space-small;
    }
  }

  &__request {
    height: 80px;
    padding-right: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;

    @include bp-large {
      height: 60px;
      padding-right: 0;
    }
  }

  &__name-container {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__name {
    @include type-basic;
    font-weight: bold;
  }

  &__buttons {
    @include bp-large {
      display: flex;
    }
  }

  &__button {
    @include type-small;
    height: 15px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
    background: {
      repeat: no-repeat;
      position: 0 center;
      size: 15px;
    }

    &--dismiss {
      margin-bottom: $space-small;
      color: $color-red;
      background-image: url("../../../assets/icons/dismiss.svg");

      @include bp-large {
        margin-right: $space-small;
        margin-bottom: 0;
      }
    }

    &--accept {
      color: $color-green;
      background-image: url("../../../assets/icons/accept.svg");
    }
  }
}
