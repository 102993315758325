@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.resend-forgot-account {
  max-width: 1110px;
  min-height: calc(100vh - 104px);
  margin: 0 auto;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include bp-large {
    height: 675px;
    align-items: center;
    margin-top: $space-large;
    margin-bottom: 10px;
    padding-top: 0;
    min-height: auto;
    background-color: $color-white;
    border: 1px solid $color-grey-5;
  }

  &__wrapper {
    padding: 0 $space-small;
    width: 100%;

    @include bp-large {
      width: 400px;
    }
  }

  &__heading {
    @include type-extra-large;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__sub-heading {
    @include type-large;
    color: $color-grey-3;
    margin-top: 0;
    margin-bottom: 25px;
  }

  &__resend-button {
    margin-top: $space-small;
    @include type-basic;
    font-weight: bold;
    color: $color-green-2;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__user-mail {
    @include type-basic;
    padding-right: $space-small;
  }

  &__wrong-email-button {
    @include type-small;
    color: $color-grey-4;
    text-decoration: underline;
  }
}
