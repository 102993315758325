@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-actions {
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-grey-5;

  &__button {
    border-right: 1px solid $color-grey-5;

    &:first-child,
    &:last-child {
      width: 40%;

      @include bp-small {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 60%;

      @include bp-small {
        width: 100%;
      }
    }

    &:last-child {
      border: none;
    }

    &--wide {
      &:first-child,
      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  &__button-content {
    @include type-basic;
    padding-left: 30px;
    font-weight: bold;
    color: $color-grey;
    background-repeat: no-repeat;
    background-size: 20px;

    &--like {
      background: {
        image: url("../../../assets/icons/like.svg");
      }
    }

    &--comment {
      background: {
        image: url("../../../assets/icons/comment.svg");
        position: 0 1px;
      }
    }

    &--share {
      background: {
        image: url("../../../assets/icons/share.svg");
      }
    }
  }
}
