@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.textarea {
  height: 150px;
  position: relative;
  background: $color-white;

  &--full-width {
    width: 100%;
  }

  &--focused {
    .textarea__label {
      @include type-small;
      transform: translate(-8px, -190%);
      color: $color-grey-4;
    }
  }

  &__label {
    @include type-basic;
    position: absolute;
    left: 8px;
    top: 10px;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    color: $color-grey-4;
  }

  &__field {
    @include type-basic;
    height: 150px;
    padding: 15px 55px 15px 10px;
    position: relative;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
    color: $color-grey;
    background-color: transparent;
    resize: none;

    &--full-width {
      width: 100%;
    }

    &--error {
      border: 1px solid $color-red;
    }
  }

  &__counter {
    @include type-small;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $color-grey-3;
  }

  &__error-message {
    @include type-error;
    position: absolute;
    right: 0px;
    z-index: 1;
  }
}
