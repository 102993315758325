@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.marketplace-sidebar__mobile {
  margin-top: 15px;
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  .marketplace-sidebar__sell-button {
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }

  button {
    transition: all 0.3s ease-out;
  }

  .marketplace-sidebar__filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    button {
      height: 40px;
      background-color: white;
      border: 1px solid $color-grey-5;
      color: $color-green;

      &:hover,
      &:active {
        background-color: darken($color-green, 5%);
        color: #ffffff;
      }

      &.active-category {
        background-color: $color-green;
        color: #ffffff;

        &:active,
        &:focus {
          outline: none;
        }

        &:hover {
          background-color: darken($color-green, 5%);
        }
      }

      &:first-child {
        margin-right: 15px;
        margin-left: 5px;

        @media (max-width: 399px) {
          margin-left: 0;
          margin-right: 5px;
        }
      }

      @media (max-width: 420px) {
        background-position: 5px 50%;
        padding-left: 40px;
      }

      @media (max-width: 399px) {
        padding-left: 35px;
      }

      @media (max-width: 350px) {
        font-size: 12px;
      }

      &:last-child {
        margin-left: 15px;
        margin-right: 5px;

        @media (max-width: 399px) {
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
  }
}
