@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farmtour-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    width: 100px;
    margin-bottom: 0;
  }

  .farmtour-title {
    text-align: center;
    font-size: 14px;
    color: $color-green;
    display: inline-block;
    margin-bottom: 5px;
  }

  .farmtour-image-wrapper {
    width: 150px;
    height: 150px;

    @media (max-width: 991px) {
      width: 100px;
      height: 100px;
    }
    border-radius: 8px;
    position: relative;
    border: 1px solid #e5e5e5;
    background-image: url("../../../assets/icons/farm-dark-grey.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    background-color: #f2f2f2;
    outline: none;

    .farmtour-image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .farm-tour-edit-button {
      width: 25px;
      height: 25px;
      position: absolute;
      top: -20px;
      right: -20px;
      border-radius: 8px;
      z-index: 1;
      background-image: url("../../../assets/icons/edit-grey.svg");
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
    }
  }
}
