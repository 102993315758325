@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.read-more-container {
  border-top: 1px solid #e5e5e5;
  padding: 10px;
  text-align: center;

  .read-more-button {
    font-size: 0.75rem;
    font-weight: normal;
    text-align: center;
    color: white;
    border-right: 1px solid #e5e5e5;
    background-color: rgba(137, 189, 36, 0.51);
    display: block;
    padding: 10px;
    text-decoration: none;
    border-radius: 20px;
    width: 50%;
    margin: 0 auto;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: $color-green-1;
    }
  }
}
