@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.profile {
  @include bp-large {
    padding-top: $space-medium;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__create-post {
    margin-bottom: $space-small;
  }

  &__feed {
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: $space-large;

    @include bp-large {
      width: 640px;
      margin: 0 $space-small;
      padding-bottom: 0;
      flex-shrink: 0;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 $space-medium;
    }
  }

  &__sidebar {
    display: none;

    @include bp-large {
      display: block;
    }

    &--right {
      @include bp-large {
        width: 160px;
        height: 300px;
        border-radius: 2px;
        border: 1px solid $color-grey-5;
        background-color: $color-white;
      }

      @include bp-extra-large {
        width: 255px;
      }
    }
  }

  &__header-content {
    @include bp-large {
      width: 160px;
      display: flex;
      flex-direction: column;
    }
  }

  &__header {
    margin-bottom: $space-big;
  }

  &__footer {
    display: none;

    @include bp-large {
      display: block;
    }
  }

  &__empty {
    @include type-basic;
    text-align: center;
    color: $color-grey-4;
  }
}
