@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.guest-modal {
  width: 320px;
  height: 400px;
  border-radius: 5px;
  background-color: $color-grey-5;
  padding: 20px;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &-description {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }

  &-confirm {
    width: 250px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background-color: $color-green;
    color: white !important;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: darken($color-green, 5%);
    }
  }

  &-registered {
    margin-top: 80px;
    padding-top: 15px;
    width: 80%;
    border-top: 1px solid $color-grey;
    text-align: center;
  }

  &-have-account {
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;
    color: $color-grey;
  }

  &-no-button {
    padding: 7px 30px;
    border: 1px solid $color-green;
    border-radius: 4px;
    color: $color-green;
    margin-top: 30px;
    transition: background-color 0.3s ease-out;
    background-color: transparent;

    &:hover {
      background-color: darken($color-grey-5, 5%);
    }
  }
}
