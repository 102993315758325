// breakpoints

@mixin bp-extra-small {
  @media (min-width: #{$extra-small}) {
    @content;
  }
}

@mixin bp-small {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin bp-extra-large {
  @media (min-width: #{$extra-large}) {
    @content;
  }
}

// font mixins

@mixin type-extra-large {
  font-size: $font-extra-large;
  font-weight: normal;
}

@mixin type-large {
  font-size: $font-large;
  font-weight: normal;
}

@mixin type-basic {
  font-size: $font-basic;
  font-weight: normal;
}

@mixin type-small {
  font-size: $font-small;
  font-weight: normal;
}

@mixin type-middle {
  font-size: $font-middle;
  font-weight: normal;
}

@mixin type-error {
  font-size: $font-small;
  font-weight: normal;
  color: $color-red;
}

// utilities mixins

@mixin break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
