@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.footer {
  background-color: $color-grey-6;

  &__content {
    max-width: $content-large;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: center;

    @include bp-large {
      display: block;
    }

    &--sidebar {
      padding: 0 20px 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__link {
    @include type-small;
    margin-right: 10px;
    position: relative;
    text-decoration: none;
    font-weight: 600;
    color: $color-grey-3;

    &::after {
      content: "";
      display: inline-block;
      width: 2px;
      height: 2px;
      position: absolute;
      top: 6px;
      right: -5px;
      background-color: $color-grey-3;

      @include bp-large {
        top: 7px;
        right: -6px;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      color: darken($color-grey-3, 5%);
    }

    &--sidebar {
      white-space: nowrap;
    }
  }
}
