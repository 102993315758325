@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.notifications-modal {
  position: absolute;
  top: 43px;
  right: -31px;
  width: 400px;
  height: 300px;
  overflow-y: auto;
  z-index: 50;
  overflow-x: hidden;
  background-color: white;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 991px) {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 50px;
    width: 100%;
    height: inherit;
  }
}
