@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.sidebar-navigation {
  width: 160px;
  position: fixed;
  top: 90px;

  @include bp-large {
    left: calc(50% - 495px);
  }

  @media screen and (min-width: 1050px) {
    left: calc(50% - 510px);
  }

  @include bp-extra-large {
    left: calc(50% - 558px);
  }

  &__button {
    @include type-basic;
    width: 100%;
    height: 30px;
    margin-bottom: $space-small;
    padding-left: 32px;
    line-height: 30px;
    display: block;
    text-align: left;
    color: $color-white;
    border-radius: 2px;
    text-decoration: none;
    background: {
      repeat: no-repeat;
      color: $color-green;
    }

    &:active,
    &:hover {
      background-color: darken($color-green, 5%);
    }

    &--newsfeed {
      background: {
        image: url("../../../assets/icons/newsfeed-lightgreen.svg");
        position: 5px 4px;
        size: 21px;
      }
    }

    &--userprofile {
      background: {
        image: url("../../../assets/icons/user-lightgreen.svg");
        position: 5px 4px;
        size: 20px;
      }
    }

    &--marketplace {
      position: relative;
      background: {
        image: url("../../../assets/icons/marketplace-lightgreen.svg");
        position: 5px 4px;
        size: 21px;
      }
    }

    &--video {
      position: relative;
      background: {
        image: url("../../../assets/icons/video-light-green.svg");
        position: 5px 4px;
        size: 21px;
      }
    }

    &--webapp {
      position: relative;
      background: {
        image: url("../../../assets/icons/logo-small.svg");
        position: 5px 4px;
        size: 21px;
      }
    }

    &--farm {
      background: {
        image: url("../../../assets/icons/farm-lightgreen.svg");
        position: 5px 5px;
        size: 22px;
      }
    }

    &--invite {
      background: {
        image: url("../../../assets/icons/members-bold-lightgreen.svg");
        position: 4px 5px;
        size: 24px;
      }
    }
  }

  &__footer {
    margin-top: $space-large;
  }
}
