@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.edit-profile-modal {
  width: 100%;
  height: 100%;
  position: relative;
  background: $color-white;

  @include bp-large {
    width: 990px;
    height: 700px;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  @include bp-extra-large {
    width: 1120px;
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    padding: $space-small $space-small 80px;
    overflow-y: scroll;

    @include bp-large {
      padding: 0;
      overflow: hidden;
      display: flex;
    }
  }

  &__profile-data {
    @include bp-large {
      padding: $space-medium $space-medium 0;
      display: flex;
    }

    @include bp-extra-large {
      padding: $space-medium 0 0 $space-medium;
    }
  }

  &__heading {
    @include type-extra-large;
    margin: 0;
    font-weight: bold;

    &--mobile {
      margin-bottom: $space-medium;

      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
        margin: 0 0 $space-big;
      }
    }

    &--delete-farm {
      margin-top: 60px;
      margin-bottom: 20px;
    }

    &--members {
      margin-bottom: 25px;
    }

    &--profile {
      margin: 0 0 $space-big;
    }

    &--password {
      margin: 0 0 20px;

      @include bp-large {
        margin: 0 0 $space-medium;
      }
    }
  }

  &__row {
    margin-bottom: $space-medium;

    &--inline {
      display: flex;
      align-items: center;
    }

    &--post-code {
      input {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__birthdate-inputs-container {
    display: flex;
  }

  &__birthdate-input {
    @include bp-large {
      margin-right: $space-small;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__form-label {
    width: 120px;
    margin: 0;
    font-size: 0.875rem;
    flex-shrink: 0;
    font-weight: bold;
  }

  &__profile-image-container {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 110px;
      height: 110px;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid $color-grey-5;
      border-radius: 100%;
      background: $color-white;
    }
  }

  &__profile-image {
    width: 102px;
    height: 102px;
    position: relative;
    border: 1px solid $color-grey-5;
    border-radius: 100%;
    background: {
      image: url("../../../assets/icons/user-dark-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 22px;
      color: $color-grey-6;
    }
  }

  &__loader-container {
    width: 102px;
    height: 102px;
    position: relative;
    border: 1px solid $color-grey-5;
    border-radius: 100%;

    .loader__load-message {
      font-size: $font-small;
    }
  }

  &__label-container {
    margin-bottom: $space-medium;
    position: relative;

    @include bp-large {
      width: 150px;
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: inline-flex;
        flex-direction: column;
      }
    }

    &--mobile {
      @include bp-large {
        display: none;
      }
    }
  }

  &__label-inner-container {
    margin-left: $space-small;

    @include bp-large {
      margin: 10px 0 0;
      text-align: center;
    }
  }

  &__label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @include bp-large {
      width: 100%;
      flex-direction: column;
    }
  }

  &__label-text {
    @include type-small;
    font-weight: bold;
    color: $color-grey-3;
  }

  &__file-input {
    display: none;
  }

  &__labels {
    @include bp-large {
      display: flex;
      flex-direction: column;
      margin-right: $space-large;
    }
  }

  &__buttons {
    width: 100%;
    padding: 10px $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small $space-medium $space-medium;
    }
  }

  &__form {
    width: 100%;

    @include bp-large {
      display: flex;
    }
  }

  &__form-content {
    margin-bottom: $space-large;

    @include bp-large {
      width: 330px;
      margin-right: $space-large;
    }

    @include bp-extra-large {
      width: 370px;
      margin-right: 100px;

      &:last-child {
        margin-right: $space-large;
      }
    }
  }

  &__submit-button-container {
    position: relative;
  }

  &__error {
    @include type-small;
    margin: $space-small 0;
    color: $color-red;

    @include bp-large {
      width: 180px;
    }

    &--password {
      width: 100%;
      margin: 0;
      display: block;
      text-align: right;
    }

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__farm {
    margin-bottom: $space-large;

    @include bp-large {
      margin-bottom: 75px;
    }
  }

  &__farm-buttons {
    display: flex;
  }

  &__join-button {
    margin-right: $space-small;
  }

  &__delete-picture-button {
    margin-top: $space-extra-small;
  }

  &__leave-farm-button {
    margin-right: $space-small;

    @include bp-large {
      margin-right: $space-medium;
    }
  }
}
