@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm-header-desktop {
  padding: 25px $space-small $space-small;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey-5;
  background-color: $color-white;

  &__top-container {
    margin-bottom: $space-medium;
  }

  &__name {
    @include type-basic;
    @include break-word;
    margin-bottom: $space-extra-small;
  }

  &__category {
    @include type-middle;
    margin: 15px 0 0 0;
    font-weight: bold;
    color: $color-grey-2;
  }

  &__farm-area-label {
    @include type-small;
    margin: 10px 0 0 0;
    color: $color-grey-3;
  }

  &__farm-area {
    @include type-middle;
    font-weight: bold;
    margin: 0 0 0 0;
    color: $color-grey-2;
  }

  &__posts {
    @include type-small;
    margin: 0;
    margin-top: 15px;
    color: $color-grey-3;
  }

  &__address-container {
    margin: $space-medium 0 $space-small;
  }

  &__address {
    @include type-basic;
    @include break-word;
    margin: 0;
  }

  &__area {
    @include type-small;
    margin: 0 0 $space-medium;
    color: $color-grey-3;
  }

  &__description {
    @include type-small;
    @include break-word;
    margin: $space-medium 0;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  &__edit-button {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: {
      image: url("../../../assets/icons/edit-grey.svg");
      repeat: no-repeat;
      size: 16px;
      position: center;
    }
  }

  &__invite {
    margin-top: $space-medium;
  }

  &__invite button {
    padding-left: $space-small;
    padding-right: $space-small;
  }
}
