@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.article-details-modal {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include bp-large {
    width: 640px;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  @include bp-extra-large {
    margin-left: -94px;
  }

  &--images {
    @include bp-large {
      width: 90vw;
      height: 90vh;
      display: flex;
      border-radius: 2px;
    }

    @include bp-extra-large {
      margin-left: 0;
    }
  }

  &__media-container {
    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        width: calc(100% - 500px);
        height: 100%;
        display: block;
        background-color: $color-black;
      }
    }
  }

  &__details-container {
    width: 100%;

    &--images {
      @include bp-large {
        width: 500px;
        overflow: hidden;
        border-left: 2px solid $color-grey-5;
      }
    }
  }

  &__header {
    height: 60px;
    padding-left: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
  }

  &__header-details {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__sold {
    @include type-basic;
    font-weight: bold;
    color: $color-grey;
  }

  &__author {
    @include type-basic;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: bold;
    color: $color-green-2;
  }

  &__author-details {
    display: flex;
  }

  &__address {
    @include type-small;
    margin-right: 10px;
    position: relative;
    font-weight: bold;
    color: $color-grey-3;

    &::after {
      content: "";
      width: 2px;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 6px;
      right: -6px;
      background-color: $color-grey-3;
    }
  }

  &__text {
    @include type-basic;
    margin-bottom: $space-small;
  }

  &__close {
    @include bp-large {
      align-self: flex-start;
    }
  }

  &__details-scroll {
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;

    @include bp-large {
      height: calc(100% - 412px);
      padding: $space-small $space-medium;
    }

    &--small {
      height: 240px;
    }
  }

  &__category {
    margin-bottom: $space-small;
  }

  &__mobile-gallery {
    height: 240px;
    margin-bottom: 20px;
    background-color: $color-black;

    @include bp-large {
      display: none;
    }
  }

  &__slider-image {
    height: 240px;

    @include bp-large {
      height: 90vh;
    }
  }

  &__data {
    padding: $space-small $space-small 0;

    @include bp-large {
      padding: 0;
    }
  }

  &__price-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__price {
    @include type-extra-large;
    font-weight: bold;
    margin: 0;
  }

  &__sold-mark {
    @include type-small;
    margin-left: $space-medium;
    padding-top: 3px;
    font-weight: bold;
    color: $color-red;
  }

  &__title {
    @include type-basic;
    margin: 0 0 $space-small;
    font-weight: bold;
  }

  &__phone-container {
    height: 30px;
    margin-bottom: $space-small;
    padding-left: $space-medium;
    display: flex;
    align-items: center;
    background: {
      image: url("../../../assets/icons/phone.svg");
      repeat: no-repeat;
      size: 15px;
      position: 0 50%;
    }

    @include bp-large {
      background-image: url("../../../assets/icons/phone-grey.svg");
    }

    .main-button {
      padding: $space-extra-small $space-small;
    }
  }

  &__phone-inner-container {
    &--mobile {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__phone {
    @include type-basic;
    margin: 0;
    font-weight: bold;
  }

  &__phone-link {
    @include type-basic;
    color: white;
    text-decoration: none;
  }

  &__contact-form {
    display: none;

    @include bp-large {
      display: block;
    }
  }

  &__success-container {
    @include type-basic;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: $space-extra-small $space-small;
    }
  }

  &__success-message {
    margin: 0;
  }

  &__edit-button {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    background: {
      image: url("../../../assets/icons/edit-grey.svg");
      repeat: no-repeat;
      size: 16px;
      position: center;
    }
  }

  // Overwrite slider styles
  .slider-control-bottomcenter {
    bottom: -35px !important;

    @include bp-large {
      bottom: 20px !important;
    }
  }
}
