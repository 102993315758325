@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.input {
  height: 30px;
  position: relative;
  background: $color-white;

  &--full-width {
    width: 100%;
  }

  &--focused {
    .input__label {
      @include type-small;
      transform: translate(-8px, -170%);
      color: $color-grey-4;
    }
  }

  &__icon {
    &::before {
      content: "";
      display: block;
      width: 22px;
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--username {
      &::before {
        content: none;

        @include bp-large {
          content: "";
          height: 14px;
          background-image: url("../../../assets/icons/email.svg");
        }
      }
    }

    &--password {
      .input__field {
        padding-right: 40px;
      }

      &::before {
        content: none;

        @include bp-large {
          content: "";
          height: 18px;
          background-image: url("../../../assets/icons/password.svg");
        }
      }
    }

    &--search {
      &::before {
        height: 14px;
        background-image: url("../../../assets/icons/search.svg");
      }
    }
  }

  &__label {
    @include type-basic;
    position: absolute;
    left: 8px;
    top: 6px;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    color: $color-grey-4;
  }

  &__field {
    @include type-basic;
    line-height: 28px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
    color: $color-grey;
    background-color: transparent;

    &--full-width {
      width: 100%;
    }

    &--icon {
      @include bp-large {
        padding-left: 32px;
      }
    }

    &--error {
      border: 1px solid $color-red;
    }

    &--search {
      padding-left: 32px;
    }
  }

  &__error-message {
    @include type-error;
    position: absolute;
    right: 0px;
    z-index: 1;
  }

  &__toggle-password-button {
    width: 20px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 7px;
    z-index: 1;
    background: {
      image: url("../../../assets/icons/hide-password.svg");
      repeat: no-repeat;
      position: center;
    }
    cursor: pointer;

    &--hidden {
      background-image: url("../../../assets/icons/show-password.svg");
    }
  }

  input {
    &:read-only {
      background-color: $color-grey-6;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
