.react-tabs {
  width: 100%;

  &__tab-list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  &__tab {
    width: 50%;
    height: 30px;
    background-color: $color-white;
    border: 1px solid $color-grey-5;
    color: $color-grey-5;
    display: flex;
    justify-content: center;
    align-items: center;

    &--selected {
      color: $color-white;
      background-color: $color-green-2;
    }
  }
}
