// colors
$color-white: #ffffff;
$color-black: #000000;
$color-green: #88bd23;
$color-green-1: rgb(137, 189, 36);
$color-green-2: #547618;
$color-green-3: #c0de96;
$color-green-4: #b6cf6d;
$color-grey: #585858;
$color-grey-2: #7f7f7f;
$color-grey-3: #ababab;
$color-grey-4: #bfbfbf;
$color-grey-5: #e5e5e5;
$color-grey-6: #f2f2f2;
$color-grey-7: #d8d8d8;
$color-red: #d20101;
$color-red-1: #ec0000;
$color-dark-blue: #3d5894;

// breakpoints
$extra-small: 360px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;

// spacing
$space-large: 50px;
$space-big: 40px;
$space-medium: 30px;
$space-small: 15px;
$space-extra-small: 5px;

// fonts
$font-extra-large: 1.5625rem;
$font-large: 1.1875rem;
$font-basic: 0.9375rem;
$font-middle: 0.82rem;
$font-small: 0.75rem;

// sizes
$content-large: 1140px;
