@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.user-register {
  .loader-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include bp-large {
    margin: 25px $space-medium;
  }

  &__headings-wrapper {
    display: none;
    @include bp-large {
      display: block;
    }
  }

  &__heading {
    @include type-extra-large;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__sub-heading {
    @include type-large;
    margin-top: 0;
    margin-bottom: $space-medium;
    color: $color-grey-4;
  }

  &__input {
    margin: $space-small 0;

    &--full-width {
      width: 100%;
    }

    @include bp-large {
      &--space-left-large {
        margin-left: $space-extra-small;
      }

      &--space-middle-large {
        margin-left: $space-extra-small;
        margin-right: $space-extra-small;
      }

      &--space-right-large {
        margin-right: $space-extra-small;
      }
    }

    &--center {
      input {
        text-align: center;
      }
    }
  }

  &__label {
    @include type-basic;
    display: flex;
    align-items: center;
    width: 120px;
    padding: 0 10px 0 0;
    color: $color-grey;
  }

  &__error {
    &--birth-date {
      @include type-error;
      margin: 0;
      width: 100%;
      text-align: right;
    }

    &--submit {
      @include type-error;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    .registration-terms {
      font-size: 9px;
      line-height: 11px;
      a {
        color: $color-green;
        text-decoration: none;
      }
    }

    &--wrap {
      flex-wrap: wrap;
      @include bp-large {
        flex-wrap: nowrap;
      }
    }

    &--full-width {
      width: 100%;
    }
  }

  &__submit-button {
    width: 100%;
    padding: 15px 0 5px 0;

    @include bp-large {
      position: relative;
      padding: 25px 0 5px 0;
    }

    button.main-button {
      width: 100% !important;
      padding: 12px;
      background-color: $color-red-1 !important;
      transition: all 0.3s ease-out;
      text-transform: uppercase;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-red-1, 5%) !important;
        outline: none;
      }
      @include bp-large {
        width: auto;
      }
    }
  }

  .account-deletion {
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    color: $color-grey;
  }
}
