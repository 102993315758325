@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.image-with-loader {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #dddddd;

  &__farmtour-post {
    width: 300px !important;
    height: 300px !important;
    border-radius: 25px;
  }

  &__image {
    &--hidden {
      display: none;
    }

    &--full-width {
      width: 100%;
    }

    &--full-width-mobile {
      width: 100%;
    }

    &--full-height {
      height: 100%;
    }

    &--auto {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--background {
      background: $color-grey-5;
    }
  }
}
