@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.select {
  height: 30px;
  position: relative;
  background: $color-white;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    border-color: $color-grey-5 transparent transparent transparent;
    position: absolute;
    right: $space-small;
    top: 12px;
  }

  &--full-width {
    width: 100%;
  }

  &__field {
    @include type-basic;
    height: 30px;
    line-height: 28px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
    color: $color-grey-4;
    background-color: transparent;
    appearance: none;

    &--value {
      color: $color-grey;
    }

    &--full-width {
      width: 100%;
    }

    &--error {
      border: 1px solid $color-red;
    }
  }

  &__error-message {
    @include type-small;
    position: absolute;
    right: 0;
    color: $color-red;
  }
}
