@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm-member {
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__member {
    display: flex;
    align-items: center;
  }

  &__details {
    padding-left: $space-small;
    cursor: default;
  }

  &__link {
    text-decoration: none;
  }

  &__name {
    @include type-basic;
    color: $color-green-2;
    text-decoration: none;
    font-weight: bold;
    margin: 0;
  }

  &__admin {
    @include type-small;
    color: $color-grey-4;
    margin: $space-extra-small 0;
    font-weight: bold;
  }

  &__delete-icon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  &__delete-button {
    @include type-error;
    display: flex;
    align-items: center;
  }
}
