@import "styles/_variables.scss";
@import "styles/_mixins.scss";

@function get-vw($target) {
  $vw-context: (1000 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

.hashtag-view {
  width: 925px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0 10px;
  }
  & > div:first-of-type {
    float: left;
  }

  .hashtag-view__post {
    display: inline-block;
    width: 295px;
    height: 200px;

    @media (max-width: 991px) {
      width: calc(100vw / 3.5);
      height: calc(calc(100vw / 3.3) * 0.7);
    }

    overflow: hidden;
    border: 1px solid $color-grey-5;

    margin-right: 10px;
    margin-bottom: 10px;
  }

  .hashtag {
    margin-top: 0;
  }
}
