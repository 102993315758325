@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.join-farm-modal {
  width: 100%;
  height: 100%;
  padding: $space-small $space-small 0;
  position: relative;
  background: $color-white;

  @include bp-large {
    width: 640px;
    height: 550px;
    padding: $space-medium $space-small 0;
  }

  &__heading {
    @include type-extra-large;
    margin: 0 0 $space-medium;
    font-weight: bold;
    color: $color-grey;
  }

  &__scroll-container {
    height: 100vh;
    padding: 0 $space-small 240px 0;
    overflow-y: scroll;
    overflow-x: hidden;

    @include bp-large {
      height: 355px;
      padding: 0 $space-small 40px 0;
    }
  }

  &__empty {
    @include type-basic;
    text-align: center;
    color: $color-grey-4;
  }

  &__buttons {
    width: 100%;
    padding: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small $space-medium $space-medium;
    }
  }

  &__farm {
    min-height: 85px;
    padding: $space-small 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-5;
  }

  &__farm-details {
    display: flex;
    align-items: center;
  }

  &__farm-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    flex-shrink: 0;
    border-radius: 100%;
    background: {
      repeat: no-repeat;
      size: cover;
      color: $color-grey-4;
      position: center;
    }
  }

  &__farm-name {
    @include type-basic;
    margin: 0 0 $space-extra-small 0;
    font-weight: bold;
    color: $color-green-2;
  }

  &__farm-members {
    @include type-small;
    margin: 0 0 $space-extra-small 0;
    font-weight: bold;
  }

  &__farm-address {
    @include type-small;
    margin: 0;
    font-weight: bold;
    color: $color-grey-4;
  }

  &__radio {
    flex-shrink: 0;
  }

  &__submit-button-container {
    position: relative;
  }

  &__error {
    @include type-small;
    margin: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
    color: $color-red;
  }
}
