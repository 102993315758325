@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.user-login {
  padding-top: $space-medium;

  @include bp-large {
    padding-top: 0;
  }

  &__form {
    position: relative;

    @include bp-large {
      display: flex;
    }
  }

  &__input {
    position: relative;
    margin-bottom: 30px;

    @include bp-large {
      margin-right: 30px;
      margin-bottom: 0;
    }

    input {
      width: 100%;

      @include bp-large {
        width: 260px;
      }
    }
  }

  &__submit-button {
    margin-top: $space-large;

    @include bp-large {
      margin-top: 0;
    }

    button {
      width: 100%;
    }
  }

  &__submit-error {
    @include type-error;
    position: absolute;
    bottom: -30px;
    right: 0;
  }

  &__forgot-link {
    @include type-small;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &--mobile {
      display: block;
      margin-top: $space-large;
      text-align: center;
      font-weight: 600;
      color: $color-green-2;

      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
        position: absolute;
        bottom: -20px;
        color: $color-white;
      }
    }
  }
}
