@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.register-list {
  padding: 25px;

  &__heading {
    @include type-extra-large;
    margin-top: 0;
    margin-bottom: $space-small;
  }

  &__sub-heading {
    @include type-large;
    margin-top: 0;
    color: $color-grey-4;
  }

  &__list {
    margin-top: 55px;

    .left-image {
      width: 630px;

      img {
        width: 100%;
      }
    }
  }
}
