@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm-header-mobile {
  border-bottom: 1px solid $color-grey-5;
  background-color: $color-white;

  &__title-image-container {
    width: 100%;
    height: 180px;
    position: relative;
  }

  &__title-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: {
      image: url("../../../assets/icons/image-dark-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 22px;
      color: $color-grey-5;
    }
    outline: none;

    &--active {
      cursor: pointer;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba($color-white, 0.1) -1%,
        rgba($color-white, 0.75) 75%,
        rgba($color-white, 0.8) 80%,
        rgba($color-white, 0.9) 90%,
        rgba($color-white, 0.92) 92%,
        rgba($color-white, 0.94) 94%,
        rgba($color-white, 0.96) 96%,
        rgba($color-white, 0.98) 98%,
        rgba($color-white, 1) 100%
      );
    }
  }

  &__farm-data {
    margin-top: -($space-big);
    padding: 0 $space-small $space-medium;
    position: relative;
  }

  &__top-container {
    display: flex;
    margin-bottom: $space-small;
  }

  &__profile-image {
    margin-right: $space-small;
  }

  &__main-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name-container {
    display: flex;
    align-items: center;
    margin-bottom: $space-extra-small;
  }

  &__name {
    @include type-basic;
    @include break-word;
    margin: 0 10px 0 0;
    font-weight: bold;
  }

  &__edit {
    width: 25px;
    height: 25px;
    background: {
      image: url("../../../assets/icons/edit-grey.svg");
      repeat: no-repeat;
      size: 16px;
      position: center;
    }

    &--title {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
      cursor: pointer;
      background-image: url("../../../assets/icons/edit-white.svg");
    }
  }

  &__category {
    @include type-middle;
    margin: 10px 0 0 0;
    font-weight: bold;
    color: $color-grey-2;
  }

  &__farm-area-label {
    @include type-small;
    margin: 10px 0 0 0;
    color: $color-grey-3;
  }

  &__farm-area {
    @include type-middle;
    font-weight: bold;
    margin: 0 0 0 0;
    color: $color-grey-2;
  }

  &__posts {
    @include type-small;
    margin: 10px 0 0 0;
    color: $color-grey-3;
  }

  &__address-container {
    margin-top: $space-small;
  }

  &__address {
    @include type-basic;
    @include break-word;
    margin: 0;
  }

  &__area {
    @include type-small;
    color: $color-grey-3;
  }

  &__description {
    @include type-small;
    @include break-word;
    margin: $space-small 0;
  }

  &__members-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__middle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__bottom-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & > div:first-child {
      margin-right: 5px;
    }
    & > div:last-child {
      margin-left: 5px;
    }
  }

  &__input {
    display: none;
  }
}
