@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.main-button {
  @include type-basic;
  min-height: 30px;
  padding: $space-extra-small $space-medium;
  color: $color-white;
  border: none;
  border-radius: 2px;
  background-color: $color-green-2;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($color-green-2, 5%);
  }

  &--disabled {
    background-color: $color-grey-5;

    &:hover,
    &:focus {
      background-color: $color-grey-5;
    }
  }

  &--large {
    height: 40px;
  }

  &--full-width {
    width: 100%;
  }

  &--secondary {
    background-color: $color-white;
    color: $color-green-2;
    border: 1px solid $color-green-2;

    &:hover,
    &:focus {
      color: $color-white;
      background-color: $color-green-2;
    }
  }

  &--teritary {
    height: auto;
    padding: 0;
    background-color: $color-white;
    color: $color-green-2;
    font-weight: bold;

    &:hover,
    &:focus {
      color: darken($color-green-2, 5%);
      background-color: $color-white;
    }
  }
}
