@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.video-with-loader {
  height: 100%;
  position: relative;

  &--limited {
    max-height: 380px;
  }

  @media (max-width: 991px) {
    max-height: 380px;
  }

  &--loaded {
    & > div {
      width: 100%;
      height: 100%;
    }
  }

  @include bp-large {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__thumbnail {
    display: none;
  }

  &__video {
    outline: none;

    video {
      &:focus,
      &:active {
        outline: none;
      }

      @media (max-width: 991px) {
        height: 380px;
      }
    }

    &:focus,
    &:active {
      outline: none;
    }

    width: 100%!important;
    height: 100%!important;

    @media (max-width: 991px) {
      height: 380px;
    }

    &--hidden {
      display: none;
    }

    &--auto {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--background {
      background: $color-grey-5;
    }
  }
}
