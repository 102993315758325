@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.new-admin-modal {
  width: 100vw;
  height: 100vh;
  border: 1px solid $color-white;
  background: $color-white;

  @include bp-large {
    width: 640px;
    height: 460px;
  }

  &__wrapper {
    padding: 20px 20px 10px 20px;
  }

  &__heading {
    margin-top: 0;
    @include type-large;
  }

  &__sub-heading {
    @include type-basic;
    color: $color-grey-4;
  }

  &__members-list {
    margin-top: $space-medium;
    height: 300px;
    margin-left: -11px;
    margin-right: -11px;
    overflow-y: auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 20px;

    @include bp-large {
      position: relative;
      padding: 0;
      bottom: -10px;
    }
  }

  &__button-cancel {
    color: $color-green-2;
  }
}
