@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.profile-header {
  margin-bottom: $space-small;
  padding: $space-medium 20px;
  border-bottom: 1px solid $color-grey-5;
  background-color: $color-white;

  @include bp-large {
    width: 160px;
    padding: 25px $space-small $space-medium;
    margin-bottom: 0;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
    position: relative;
  }

  &__main-content {
    display: flex;
    margin-bottom: $space-small;

    @include bp-large {
      margin-bottom: 0;
      display: block;
    }
  }

  &__image {
    margin-right: 20px;

    @include bp-large {
      margin: 0 0 25px 0;
      display: flex;
      justify-content: center;
    }
  }

  &__data {
    padding-top: $space-small;

    @include bp-large {
      padding: 0;
    }
  }

  &__name-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__name {
    @include type-basic;
    @include break-word;
    margin: 0;
    font-weight: bold;
  }

  &__edit-button {
    width: 25px;
    height: 25px;
    background: {
      image: url("../../../assets/icons/edit-grey.svg");
      repeat: no-repeat;
      size: 16px;
      position: center;
    }

    @include bp-large {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  &__farm-name {
    @include type-basic;
    @include break-word;
    display: block;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: $color-green-2;
    text-decoration: none;
  }

  &__counters {
    display: flex;
  }

  &__posts-count {
    @include type-small;
    margin: 0;
    color: $color-grey-3;
  }

  &__articles-count {
    @include type-small;
    margin: 0;
    color: $color-grey-3;

    &--divider {
      margin-left: $space-small;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 2px;
        background: $color-grey-3;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
      }
    }
  }

  &__description {
    @include type-small;
    @include break-word;
    margin: 0;

    @include bp-large {
      margin: $space-medium 0 0 0;
    }
  }
}
