@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.activities-modal {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include bp-large {
    width: 640px;
    height: 355px;
    overflow: hidden;
    border: 1px solid $color-grey-5;
    border-radius: 2px;
  }

  @include bp-extra-large {
    margin-left: -94px;
  }

  &__header {
    height: 40px;
    padding-left: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
    position: relative;
    z-index: 1;
  }

  &__tab-button {
    @include type-small;
    height: 44px;
    color: $color-grey;
    font-weight: bold;
    padding-right: 20px;

    @include bp-large {
      padding: 0 10px;
    }

    &--likes-active,
    &--shares-active,
    &--comments-active {
      color: $color-green;
      border-bottom: 3px solid $color-green;
    }

    &--views-active {
      .activities-modal__tab-button-content--views {
        background-image: url("../../../assets/icons/eye.svg");
      }
    }

    &--shares-active {
      .activities-modal__tab-button-content--shares {
        background-image: url("../../../assets/icons/shares.svg");
      }
    }

    &--comments-active {
      .activities-modal__tab-button-content--comments {
        background-image: url("../../../assets/icons/comments.svg");
      }
    }

    &--comments {
      @include bp-large {
        display: none;
      }
    }
  }

  &__tab-button-content {
    padding-left: 25px;
    background: {
      repeat: no-repeat;
      position: center left;
      color: transparent;
    }

    &--likes {
      background-image: url("../../../assets/icons/likes-grey.svg");
    }

    &--shares {
      background-image: url("../../../assets/icons/shares-grey.svg");
    }

    &--comments {
      background-image: url("../../../assets/icons/comments-grey.svg");
    }
  }

  &__tab-button-text {
    display: none;

    @include bp-large {
      display: inline-block;
    }
  }

  &__content {
    height: calc(100vh - 40px);
    padding: 0 $space-small;
    overflow-y: scroll;

    @include bp-large {
      height: 315px;
    }

    &--comments {
      height: 100%;
      padding: 0;

      @include bp-large {
        display: none;
      }
    }
  }

  &__load-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
