@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm-item {
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
  }

  &__image {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background: {
      image: url("../../../assets/icons/farm-light-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 50%;
      color: $color-grey-5;
    }
  }

  &__details {
    padding-left: $space-small;
    cursor: default;
  }

  &__name {
    @include type-basic;
    color: $color-green-2;
    text-decoration: none;
    font-weight: bold;
  }

  &__members {
    @include type-small;
    color: $color-grey;
    margin: $space-extra-small 0;
    font-weight: bold;
  }

  &__adress {
    @include type-small;
    color: $color-grey-4;
    margin: $space-extra-small 0;
    font-weight: bold;
  }
}
