@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.contact-seller-form {
  &__row {
    margin-bottom: $space-medium;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
