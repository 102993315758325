@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.radio {
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
  font-weight: bold;
  cursor: pointer;
  color: $color-green;

  &__input {
    display: none;
  }

  &__checkmark {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 50%;
    border: 2px solid $color-grey-4;
    background-color: $color-white;
  }

  input:checked ~ &__checkmark {
    border: 2px solid $color-green;
    background-color: $color-white;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ &__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    width: 7px;
    height: 7px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background: $color-green;
  }
}
