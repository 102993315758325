@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.main-header {
  height: 60px;

  &__farmfeed {
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-right: 20px;
  }

  &__container {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 50;
    background-color: $color-green;
  }

  &__content {
    max-width: $content-large;
    height: 100%;
    margin: 0 auto;
    padding: 0 $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &--left {
      display: flex;
      width: 100%;

      @include bp-large {
        width: auto;
      }
    }
  }

  &__search {
    width: 100%;
    margin-left: $space-small;
    margin-right: 10px;
    @include bp-large {
      margin-left: 70px;
      width: 450px;
    }

    @include bp-extra-large {
      margin-left: 25px;
    }
  }

  &__user-content {
    display: flex;
    align-items: center;
  }

  &__user {
    display: none;

    @include bp-large {
      display: flex;
      align-items: center;
    }

    a {
      text-decoration: none;
    }
  }

  &__user-name {
    margin: 0 $space-medium 0 $space-small;
    color: $color-white;
  }

  &__logo {
    height: 26px;

    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__menu-button {
    width: 25px;
    height: 25px;
    background: {
      image: url("../../../assets/icons/menu.svg");
      repeat: no-repeat;
      size: 20px;
      position: center;
    }

    &--open {
      background: {
        image: url("../../../assets/icons/close-white.svg");
        size: 15px;
      }
    }
  }
}
