.default-layout {
  &__outer-content {
    @include bp-large {
      padding-top: $space-medium;
      display: flex;
      justify-content: center;
    }
  }

  &__sidebar {
    display: none;

    @include bp-large {
      display: block;
      width: 160px;
    }

    &--right {
      width: 160px;

      @include bp-extra-large {
        width: 255px;
      }
    }
  }

  &__main-content {
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: $space-large;
    flex-shrink: 0;

    @include bp-large {
      margin: 0 $space-small;
      padding-bottom: 0;
      display: block;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 $space-medium;
    }
  }
}
