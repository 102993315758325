@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.delete-button {
  @include type-small;
  height: 20px;
  padding-left: 22px;
  font-weight: bold;
  color: $color-red;
  background: {
    image: url("../../../assets/icons/delete-red.svg");
    repeat: no-repeat;
    size: 11px;
    position: 0 3px;
  }
}
