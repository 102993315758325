@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.activity-item {
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-grey-5;

  &__avatar {
    margin-right: $space-small;
  }

  &__link {
    text-decoration: none;
  }

  &__author {
    @include type-basic;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: bold;
    color: $color-green-2;
  }
}
