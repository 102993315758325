@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.share-modal {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include bp-large {
    width: 640px;
    height: auto;
    position: static;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
  }

  @include bp-extra-large {
    margin-left: -94px;
  }

  &__header {
    height: 40px;
    padding-left: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
  }

  &__title {
    @include type-small;
    padding-left: 25px;
    font-weight: 700;
    color: $color-grey-3;
    background: {
      image: url(../../../assets/icons/share-light-grey.svg);
      repeat: no-repeat;
      size: 14px;
    }
  }

  &__content {
    height: calc(100vh - 100px);
    padding: $space-small $space-small 0;
    overflow-y: auto;

    @include bp-large {
      height: auto;
      max-height: 500px;
    }
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__message {
    margin-bottom: $space-small;
  }

  &__message-field {
    display: flex;
    align-items: flex-start;
  }

  &__textarea {
    @include type-basic;
    width: 100%;
    max-height: 140px;
    padding: 0;
    line-height: 28px;
    resize: none;
    outline: none;
    border: none;
    color: $color-grey;

    @include bp-large {
      @include type-large;
      padding-top: 2px;
      max-height: 180px;
    }
  }

  &__counter {
    @include type-small;
    margin: 0;
    flex-shrink: 0;
    text-align: right;
    color: $color-grey-3;
  }

  &__shared-content {
    margin-bottom: $space-medium;
  }

  &__footer {
    width: 100%;
    padding: 0 $space-small $space-small;
    position: absolute;
    bottom: 0;
    background-color: $color-white;

    @include bp-large {
      position: static;
      bottom: auto;
    }
  }
}
