@import "styles/_variables.scss";

.indicator-wrapper {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  width: 28px;
  height: 28px;

  &.no-sizes {
    width: auto;
    height: auto;
  }
}

.indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  right: -4px;
  top: -4px;
  background-color: $color-red-1;
}
