@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.text-post-details-modal {
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include bp-large {
    width: 640px;
    border-radius: 2px;
  }

  @include bp-extra-large {
    margin-left: -94px;
  }

  &__header {
    height: 60px;
    padding-left: $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
  }

  &__header-details {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__author {
    @include type-basic;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: bold;
    color: $color-green-2;
  }

  &__text {
    @include type-basic;
    padding: $space-small;

    @include bp-large {
      height: 100px;
      overflow-y: scroll;
    }
  }

  &__close {
    @include bp-large {
      align-self: flex-start;
    }
  }

  &__details-scroll {
    height: calc(100vh - 170px);
    padding-bottom: 100px;
    overflow-y: auto;

    @include bp-large {
      height: 100px;
      padding-bottom: 0;
    }
  }

  &__actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $color-white;

    @include bp-large {
      position: static;
    }
  }

  &__comments {
    display: none;

    @include bp-large {
      display: block;
      border-top: 1px solid $color-grey-5;
    }
  }
}
