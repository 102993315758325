@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.invite-modal {
  width: 100%;
  height: 100%;
  padding: $space-small $space-small 0;
  position: relative;
  background: $color-white;

  @include bp-large {
    width: 620px;
    height: 630px;
    padding: $space-medium $space-small 0;
    border-radius: 2px;
    overflow: hidden;
  }

  &__heading {
    @include type-extra-large;
    margin: 0 0 $space-small;
    font-weight: bold;
  }

  &__sub-heading {
    @include type-large;
    margin: 0 0 10px;
    color: $color-grey-4;
  }

  &__scroll-container {
    height: calc(100vh - 232px);
    padding-top: 20px;
    overflow-y: auto;

    @include bp-large {
      height: 382px;
    }
  }

  &__row {
    margin-bottom: $space-medium;
  }

  &__invitation {
    margin-bottom: $space-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__email {
    @include type-basic;
    margin: 0;
  }

  &__message {
    @include type-basic;
    margin: $space-extra-small 0 0;
  }

  &__remove-button {
    width: 25px;
    height: 25px;
    background: {
      image: url("../../../assets/icons/delete-red.svg");
      repeat: no-repeat;
      size: 12px;
      position: center;
    }
  }

  &__add-email-button {
    height: 30px;
    margin-bottom: $space-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-green-2;
    border-radius: 2px;
    border: 1px solid $color-green-2;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $color-white;
      background-color: darken($color-green-2, 5%);
      border: 1px solid darken($color-green-2, 5%);
    }

    &--disabled {
      pointer-events: none;
      color: $color-grey-5;
      border: 1px solid $color-grey-5;

      &:hover,
      &:focus {
        color: $color-grey-5;
        background-color: $color-white;
        border: 1px solid $color-grey-5;
      }
    }
  }

  &__buttons {
    width: 100%;
    padding: $space-small;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small;
    }
  }

  &__form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textarea {
    height: 110px;
  }

  .textarea__field {
    height: 110px;
  }
}
