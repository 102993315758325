@import "styles/_variables.scss";

.notifications-no-more {
  padding: 30px;

  &-message {
    font-size: 14px;
    color: $color-grey-2;
    text-align: center;
  }
}
