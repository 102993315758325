@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.options-menu {
  width: 100%;
  height: calc(100vh - 60px);
  padding-bottom: 60px;
  position: fixed;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background-color: $color-grey-6;

  @media (max-width: 991px) {
    //top: 115px;
  }

  @include bp-large {
    width: 150px;
    height: auto;
    padding-bottom: 0;
    position: absolute;
    top: 62px;
    left: auto;
    right: $space-small;
    overflow-y: hidden;
    background: $color-green;
  }

  @media screen and (min-width: 1360px) {
    right: -105px;
  }

  &__user-container {
    @include bp-large {
      display: none;
    }
  }

  &__user-link {
    text-decoration: none;
  }

  &__user {
    margin-bottom: $space-small;
    padding: $space-small 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;
    background: $color-white;
    @media (max-width: 991px) {
      display: none;
    }
  }

  &__user-name {
    @include type-basic;
    margin: 0 0 0 18px;
    font-weight: bold;
    color: $color-grey;
  }

  &__links {
    margin-bottom: $space-small;
    border-bottom: 1px solid $color-grey-5;
    border-top: 1px solid $color-grey-5;
    background: $color-white;

    @include bp-large {
      margin-bottom: 0;
      padding: 0;
      background: $color-green;
      border-bottom: none;
      border-top: none;
    }
  }

  &__link {
    @include type-large;
    padding: 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $color-grey;
    border-bottom: 1px solid $color-grey-5;
    outline: none;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      margin-right: 20px;
      border-radius: 100%;
      border: 1px solid $color-green;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small;
      color: $color-white;

      &::before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 12px;
        border-radius: 100%;
        border: 1px solid $color-white;
      }
    }
  }

  &__log-out {
    @include type-large;
    width: 100%;
    padding: 20px 20px 20px 65px;
    text-align: left;
    color: $color-grey;
    border-bottom: 1px solid $color-grey-5;
    border-top: 1px solid $color-grey-5;
    background: {
      image: url("../../../assets/icons/logout-green.svg");
      repeat: no-repeat;
      size: 25px;
      position: 20px 20px;
      color: $color-white;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background: {
        image: url("../../../assets/icons/logout.svg");
        size: 15px;
        position: $space-small $space-small;
        color: $color-green;
      }
    }
  }

  &__hofprofile {
    position: relative;
    @include type-large;
    width: 100%;
    padding: 20px 20px 20px 20px;
    text-align: left;
    color: $color-grey;

    &.userprofile {
      @media (max-width: 991px) {
        border-bottom: 1px solid $color-grey-5;
      }
      display: inline-block;
    }

    img {
      position: absolute;
      left: 20px;
      top: 18px;
      width: 25px;
      height: 25px;
      border-radius: 20px;
    }

    span {
      display: inline-block;
      padding-left: 45px;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background-color: $color-green;
      img {
        left: 15px;
        top: 15px;
        width: 15px;
        height: 15px;
      }

      span {
        padding-left: 0;
      }
    }
  }

  &__marketplace {
    @include type-large;
    display: block;
    width: 100%;
    padding: 20px 20px 20px 65px;
    text-align: left;
    color: $color-grey;
    text-decoration: none;
    border-top: 1px solid $color-grey-5;
    background: {
      image: url("../../../assets/icons/marketplace.svg");
      repeat: no-repeat;
      size: 25px;
      position: 20px 17px;
      color: $color-white;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background: {
        image: url("../../../assets/icons/marketplace-white.svg");
        size: 15px;
        position: $space-small $space-small;
        color: $color-green;
      }
    }
  }

  .articles-count {
    display: inline-block;
    margin-left: 10px;
    color: white;

    @media (max-width: 991px) {
      color: $color-grey;
      margin-left: 20px;
    }
  }

  &__farmlifes {
    @include type-large;
    display: block;
    width: 100%;
    padding: 20px 20px 20px 65px;
    text-align: left;
    color: $color-grey;
    text-decoration: none;
    border-top: 1px solid $color-grey-5;
    background: {
      image: url("../../../assets/icons/info-solid-green.svg");
      repeat: no-repeat;
      size: 10px;
      position: 27px 17px;
      color: $color-white;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background: {
        image: url("../../../assets/icons/info-solid.svg");
        size: 6px;
        position: 19px $space-small;
        color: $color-green;
      }
    }
  }

  &__video {
    @include type-large;
    display: block;
    width: 100%;
    padding: 20px 20px 20px 65px;
    text-align: left;
    color: $color-grey;
    text-decoration: none;
    border-top: 1px solid $color-grey-5;
    background: {
      image: url("../../../assets/icons/video.svg");
      repeat: no-repeat;
      size: 25px;
      position: 20px 17px;
      color: $color-white;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background: {
        image: url("../../../assets/icons/video-white.svg");
        size: 15px;
        position: $space-small $space-small;
        color: $color-green;
      }
    }
  }

  &__webapp {
    @include type-large;
    display: block;
    width: 100%;
    padding: 20px 20px 20px 65px;
    text-align: left;
    color: $color-grey;
    text-decoration: none;
    border-top: 1px solid $color-grey-5;
    background: {
      image: url("../../../assets/icons/logo-green.svg");
      repeat: no-repeat;
      size: 25px;
      position: 20px 17px;
      color: $color-white;
    }

    @include bp-large {
      @include type-small;
      padding: $space-small $space-small $space-small 42px;
      color: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: none;
      background: {
        image: url("../../../assets/icons/logo-small.svg");
        size: 15px;
        position: $space-small $space-small;
        color: $color-green;
      }
    }
  }

  &__footer {
    @include bp-large {
      display: none;
    }
  }
}
