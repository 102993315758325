@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.create-post-button {
  @include type-small;
  width: calc(100% / 3);
  height: 40px;
  color: $color-green;
  border-right: 1px solid $color-grey-5;

  &:hover,
  &:focus {
    color: darken($color-green, 5%);
  }

  &:last-child {
    border: none;
  }

  &__content {
    padding-left: 22px;
    background-repeat: no-repeat;

    &--text {
      display: inline-block;
      padding-top: 1px;
      height: 16px;
      background: {
        image: url("../../../assets/icons/post.svg");
        size: 14px;
      }
    }

    &--image {
      height: 16px;
      padding-left: 30px;
      background: {
        image: url("../../../assets/icons/image.svg");
        size: 22px;
      }
    }

    &--video {
      height: 22px;
      padding-left: 26px;
      display: flex;
      align-items: center;
      background: {
        image: url("../../../assets/icons/video.svg");
        size: 19px;
        position: 0 1px;
      }
    }

    &--article {
      display: inline-block;
      padding-top: 1px;
      height: 16px;
      background: {
        image: url("../../../assets/icons/marketplace.svg");
        size: 15px;
      }
    }
  }

  &__text {
    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__input-button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__input {
    display: none;
  }
}
