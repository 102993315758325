@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-comments {
  height: calc(100% - 35px);
  padding: 0 0 13px $space-small;
  position: relative;

  @include bp-large {
    padding-right: $space-small;
    border-top: 1px solid $color-grey-5;
  }

  &__comments-container {
    height: 100%;
    padding: 0 25px 60px 0;
    overflow-y: auto;

    @include bp-large {
      height: auto;
      max-height: 250px;
      padding: 0 25px 0 0;
    }

    &--absolute {
      padding-bottom: 10px;
    }
  }

  &__comment {
    margin-bottom: $space-extra-small;

    &:first-child {
      margin-top: $space-small;
    }
  }

  &__avatar {
    position: absolute;
    top: $space-small;
    left: $space-small;
  }

  &__input-container {
    width: 100%;
    min-height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include bp-large {
      border-radius: 2px;
      position: relative;
      border: none;
      bottom: 0;
    }

    &--absolute {
      width: 100%;
      position: absolute;
      bottom: -50px;
    }
  }

  &__input {
    @include type-basic;
    width: 100%;
    max-height: 90px !important;
    height: 60px;
    padding: 19px 90px 0 60px;
    line-height: 20px;
    resize: none;
    border: none;
    border-top: 1px solid $color-grey-6;

    @include bp-large {
      border: 1px solid $color-grey-6;
    }

    &--absolute {
      border: none;
      border-top: 1px solid $color-grey-6;
    }
  }

  &__create-button {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 18px;
    background: {
      image: url("../../../assets/icons/send.svg");
      repeat: no-repeat;
      position: center;
    }

    &--active {
      background-image: url("../../../assets/icons/send-green.svg");
    }
  }

  &__counter {
    @include type-small;
    position: absolute;
    right: 50px;
    top: 24px;
    color: $color-grey-3;
  }

  &__empty {
    @include type-basic;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
