@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.edit-post-modal {
  width: 100%;
  height: 100%;
  position: relative;
  background: $color-white;

  &__remove-button {
    width: 15px;
    height: 20px;
    background: {
      image: url("../../../assets/icons/delete.svg");
      repeat: no-repeat;
      position: center;
    }
    position: absolute;
    top: 15px;
    right: 15px;
    box-shadow: 2px 2px 4px 0px rgba($color-black, 0.4);
    border-radius: 2px;
  }

  &__image-container {
    margin-top: $space-small;
    position: relative;
  }

  &__image-upload-btn-container {
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }

  &__video-upload-btn-container {
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }

  @include bp-extra-large {
    margin-right: 95px;
  }

  &__counter {
    @include type-small;
    margin: 0;
    padding: 0 $space-small;
    text-align: right;
    color: $color-grey-3;

    &--mobile-hidden {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__avatar {
    margin-right: $space-small;
  }

  &__textarea-container {
    width: 100%;

    &--mobile {
      display: block;

      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__textarea {
    @include type-large;
    width: 100%;
    padding: 5px 0 0;
    resize: none;
    outline: none;
    border: none;
    color: $color-grey;

    &--small {
      @include type-basic;
      padding: 0;
      line-height: 30px;
    }
  }

  &__image-placeholder {
    position: relative;
    width: 170px;
    height: 170px;
  }

  &__loader-wrapper {
    position: absolute;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-grey-4;
    background: white;
    z-index: 2;
  }

  @include bp-large {
    width: 640px;
    height: auto;
    //display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  &__scroll-container {
    width: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding: $space-small 0 80px;

    @include bp-large {
      overflow: hidden;
      padding: $space-medium 0 80px;
    }
  }

  &__video-container {
    margin-top: $space-small;
    position: relative;
    background: {
      image: url("../../../assets/icons/video-white.svg");
      repeat: no-repeat;
      size: 140px;
      position: center;
      color: $color-grey-4;
    }
  }

  &__preview {
    width: 100%;
    display: block;
  }

  &__header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-5;

    &--desktop {
      display: none;

      @include bp-large {
        display: flex;
      }
    }

    &--mobile {
      padding: 0 0 0 $space-small;

      @include bp-large {
        display: none;
      }

      span {
        @include type-small;
        font-weight: bold;
        color: $color-grey-4;
      }
    }
  }

  &__heading {
    @include type-extra-large;
    width: 100%;
    margin: 0 0 $space-medium;
    font-weight: bold;
    padding: 0 $space-small;

    @include bp-large {
      margin-bottom: $space-big;
    }
  }

  &__row {
    margin-bottom: $space-medium;

    &--inline {
      display: flex;
      align-items: center;
    }

    &--center {
      input {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__form-label {
    width: 85px;
    margin: 0;
    font-size: 0.875rem;
    flex-shrink: 0;
    font-weight: bold;
  }

  &__buttons {
    width: 100%;
    padding: 10px $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small $space-medium $space-medium;
    }
  }

  &__form {
    width: 100%;

    @include bp-large {
      display: flex;
    }
  }

  &__form-content {
    margin-bottom: $space-large;

    @include bp-large {
      width: 100%;
      //margin-right: $space-large;
    }
  }

  &__inner-content {
    display: flex;
  }

  &__text-container {
    height: calc(100% - 170px);
    padding: 0 $space-small;
    display: flex;

    @include bp-large {
      height: auto;
    }

    &--small {
      padding-bottom: 0;
    }
  }

  &__submit-button-container {
    position: relative;
  }

  &__error {
    @include type-small;
    margin: $space-small 0;
    padding-left: $space-small;
    color: $color-red;

    @include bp-large {
      width: 180px;
    }

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__farm {
    margin-bottom: $space-large;

    @include bp-large {
      margin-bottom: 75px;
    }
  }

  &__farm-buttons {
    display: flex;
  }

  &__join-button {
    margin-right: $space-small;
  }

  &__delete-picture-button {
    margin-top: $space-extra-small;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    @include bp-large {
      width: 370px;
      height: 560px;
      padding-bottom: $space-medium;
      overflow-y: auto;
    }
  }

  &__image {
    width: 170px;
    height: 170px;
    margin: 0 $space-medium $space-medium 0;
    display: inline-block;
    position: relative;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    @include bp-large {
      margin-right: 0;
      &:nth-child(odd) {
        margin-right: $space-medium;
      }
    }
  }

  &__delete-image {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    background: {
      //image: url("../../../assets/icons/delete.svg");
      repeat: no-repeat;
      size: 12px;
      position: center;
    }
  }

  &__label {
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-grey-4;
    cursor: pointer;
    position: absolute;
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label-text {
    @include type-small;
    padding-top: 20px;
    color: $color-grey-3;
    font-weight: bold;
    background: {
      //image: url("../../../assets/icons/image-light-grey.svg");
      repeat: no-repeat;
      size: 20px;
      position: center 0;
    }
  }

  &__input {
    display: none;
  }

  &__error {
    @include type-small;
    width: 100%;
    margin: $space-extra-small 0;
    color: $color-red;

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__submit-container {
    padding: $space-small;
    background: $color-white;

    &--mobile {
      display: flex;
      justify-content: space-between;
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__footer-container {
    width: 100%;
    position: absolute;
    bottom: 0;

    @include bp-large {
      display: none;
    }
  }

  &__footer {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $color-grey-5;

    @include bp-large {
      display: none;
    }
  }
}

.edit-post-button {
  @include type-small;
  width: calc(100% / 2);
  height: 40px;
  color: $color-green;
  border-right: 1px solid $color-grey-5;

  &:hover,
  &:focus {
    color: darken($color-green, 5%);
  }

  &:last-child {
    border: none;
  }

  &__content {
    padding-left: 22px;
    background-repeat: no-repeat;

    &--text {
      display: inline-block;
      padding-top: 1px;
      height: 16px;
      background: {
        image: url("../../../assets/icons/post.svg");
        size: 14px;
      }
    }

    &--image {
      height: 16px;
      padding-left: 30px;
      background: {
        image: url("../../../assets/icons/image.svg");
        size: 22px;
      }
    }

    &--video {
      height: 22px;
      padding-left: 26px;
      display: flex;
      align-items: center;
      background: {
        image: url("../../../assets/icons/video.svg");
        size: 19px;
        position: 0 1px;
      }
    }

    &--article {
      display: inline-block;
      padding-top: 1px;
      height: 16px;
      background: {
        image: url("../../../assets/icons/marketplace.svg");
        size: 15px;
      }
    }
  }

  &__text {
    &--mobile {
      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
      }
    }
  }

  &__input-button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__input {
    display: none;
  }
}
