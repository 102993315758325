@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.auth-header {
  height: 60px;

  @include bp-large {
    height: 100px;
  }

  &__container {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: $color-green;

    @include bp-large {
      height: 100px;
    }
  }

  &__content {
    max-width: $content-large;
    height: 100%;
    padding: 0 $space-small;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp-large {
      justify-content: space-between;
    }
  }

  &__logo {
    height: 32px;
  }

  &__login {
    display: none;

    @include bp-large {
      display: block;
    }
  }
}
