@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm-members {
  &__container {
    margin: 0 0 10px;
  }

  &__members {
    @include type-small;
    color: $color-green;
    margin: 0;
  }

  &__avatars {
    margin: 10px 0;
    display: flex;
  }

  &__avatar {
    margin-right: $space-extra-small;
  }

  &__requests {
    position: relative;

    @include type-small;
    color: $color-grey-3;
  }
  .indicator {
    width: 8px;
    height: 8px;
    background-color: $color-red-1;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: -2px;
    right: -6px;
  }
}
