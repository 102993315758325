@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.bottom-navigation-bar {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 49;
  display: flex;
  background-color: $color-green;

  .camera-button {
    position: relative;
    width: 75px;
    text-align: center;
  }

  @include bp-large {
    display: none;
  }

  &__button {
    height: 100%;
    width: 25%;
    background-repeat: no-repeat;
    outline: none;

    &--newsfeed {
      background: {
        image: url("../../../assets/icons/newsfeed-white.svg");
        size: 22px;
        position: center;
      }
    }

    &--farm {
      background: {
        image: url("../../../assets/icons/farm-white.svg");
        size: 24px;
        position: center;
      }
    }

    &--post {
      position: absolute;
      width: 75px;
      height: 75px;
      left: -5px;
      top: -15px;
      flex-shrink: 0;
      background: {
        image: url("../../../assets/icons/camera-sm.png");
        size: cover;
      }
    }

    &--marketplace {
      text-align: center;
      background: {
        image: url("../../../assets/icons/marketplace-white.svg");
        size: 23px;
        position: center;
      }
    }

    &--invite {
      background: {
        image: url("../../../assets/icons/members-bold-white.svg");
        size: 28px;
        position: center;
      }
    }
  }
}
