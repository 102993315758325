@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.modal-container {
  min-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-black, 0.6);

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    position: relative;

    @include bp-large {
      width: auto;
      height: auto;
    }

    &--auto {
      width: auto;
      height: auto;
    }

    &--full-width {
      width: 100%;
      height: auto;
    }
  }
}
