@import "styles/_variables.scss";
@import "styles/_mixins.scss";
@import "styles/_tabs.scss";

.user-auth {
  // NOTE: 104px is equal to header and footer height
  min-height: calc(100vh - 124px);
  background: $color-grey-6;
  margin-bottom: $space-extra-small;

  @include bp-large {
    min-height: auto;
  }

  &__content {
    height: 100%;
    margin: 0 $space-medium;
    display: flex;
    @include bp-large {
      width: 1109px;
      margin: $space-large auto 0 auto;
      border: 1px solid $color-grey-5;
    }
  }

  &__login {
    display: block;

    @include bp-large {
      display: none;
    }
  }

  &__static {
    width: 680px;
    background-color: $color-white;
  }

  &__register {
    position: relative;
    width: 430px;
    background-color: $color-grey-5;
  }

  &__about-farmlifes {
    background-color: white;
    border-radius: 8px;
    border: 1px solid $color-grey-5;
    text-align: center;
    padding: $space-small;
    margin: $space-small;

    p {
      margin-top: 0;
      font-size: 16px;
      color: $color-grey;
    }

    a {
      color: $color-grey-2;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }
  }
}

.auth-footer {
  margin-bottom: $space-large;
  @include bp-large {
    margin-bottom: 0;
  }
}

.auth-loader {
  flex: 1;
  display: flex;
  height: calc(100vh - 160px);
  align-items: center;
  justify-content: center;
}
