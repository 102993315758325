@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.main-menu-notification {
  position: relative;

  @media screen and (max-width: 991px) {
    position: static;
  }

  .notifications-wrapper {
    position: relative;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.notifications-wrapper {
  @media screen and (min-width: 992px) {
    margin-right: 20px;
    padding-top: 3px;
  }

  @media screen and (max-width: 991px) {
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    padding-top: 3px;
  }

  [type="button"] {
    width: 20px;
    height: 20px;

    @media screen and (max-width: 991px) {
      width: 28px;
      height: 28px;
    }

    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
  .notifications-bell {
    width: 100%;
    height: 100%;
  }

  .unread-circle {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    display: none;
    background: {
      image: url("../../../assets/icons/circle.svg");
      repeat: no-repeat;
      size: 10px;
    }

    &.circle-visible {
      display: block;
    }
  }
}
