@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farmmap-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
  margin-bottom: 30px;
  margin-right: 20px;

  .close-map-button {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;

    padding: 20px 30px 20px 45px;
    background-color: #c92a2a;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background: {
      image: url("../../../assets/icons/close-white.svg");
      repeat: no-repeat;
      size: 10px;
      position: 25px center;
    }
    &:hover {
      transition: all 0.3s ease;
      transform: scale(1.1);
    }
  }

  @media (max-width: 991px) {
    width: 100px;
    margin-bottom: 0;
  }

  &__full {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10000;
  }

  .map-marker {
    text-align: left;
    position: relative;
    padding-right: 35px;

    a {
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }

    .farmlifes-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 0;
      right: 0;

      background: {
        image: url("../../../assets/icons/logo-green.svg");
        repeat: no-repeat;
        size: 22px;
        color: $color-white;
      }
    }
    .map-farm-name {
      font-size: 20px;
      color: $color-grey-4;
      display: block;
      margin-bottom: 5px;
    }
    .map-farm-address {
      font-size: 14px;
      color: $color-grey-2;
    }
  }

  .farmmap-title {
    text-align: center;
    font-size: 14px;
    color: $color-green;
    display: inline-block;
    margin-bottom: 5px;
  }

  .farmmap-image-wrapper {
    width: 150px;
    height: 150px;

    @media (max-width: 991px) {
      width: 100px;
      height: 100px;
    }

    /*&__full {
      position: fixed;
      //bottom: 50px;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      //height: calc(100% - 110px);

      //@media (min-width: 992px) {
      bottom: 0;
      //height: calc(100% - 60px);
      height: 100%;
      //}
    }*/

    &__full {
      position: fixed;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: calc(100%);
    }

    border-radius: 8px;
    position: relative;
    border: 1px solid #e5e5e5;
    background-image: url("../../../assets/icons/farm-dark-grey.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    background-color: #f2f2f2;
    outline: none;

    .farmmap-image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .farm-map-edit-button {
      width: 25px;
      height: 25px;
      position: absolute;
      top: -20px;
      right: -20px;
      border-radius: 8px;
      z-index: 1;
      background-image: url("../../../assets/icons/edit-grey.svg");
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
    }
  }
}
