@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.register-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  &__icon {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &--placeholder {
      background-color: $color-green;
    }
  }

  &__text-wrapper {
    padding: 0 $space-medium;
  }

  &__text {
    @include type-basic;
  }

  &__heading {
    @include type-large;
    color: $color-green;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
