@import "styles/_variables.scss";

.carousel {
  &__prev-slide,
  &__next-slide {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 11px;
      background: $color-grey-2;
      position: absolute;
      border-radius: 2px;
    }

    &::before {
      top: 12px;
      transform: rotate(-45deg);
      left: 10px;
    }

    &::after {
      top: 6px;
      transform: rotate(45deg);
      left: 11px;
    }

    &--active {
      &::before,
      &::after {
        background: $color-white;
      }
    }
  }

  &__next-slide {
    margin-right: 10px;

    &::before {
      height: 10px;
      transform: rotate(45deg);
      left: 11px;
    }

    &::after {
      height: 11px;
      transform: rotate(-45deg);
    }
  }
}
