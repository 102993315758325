@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.title-image-modal {
  width: 100vw;
  height: 265px;
  border: 1px solid $color-white;
  background: $color-white;

  @include bp-large {
    width: 920px;
    height: 590px;
  }

  &__image-container {
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp-large {
      height: 500px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background: {
      repeat: no-repeat;
      position: center;
    }
  }

  &__buttons {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    @include bp-large {
      height: 90px;
    }
  }

  &__error {
    @include type-small;
    color: $color-red;
  }
}
