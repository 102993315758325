@import "variables.scss";
@import "mixins.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
@import "../../node_modules/react-html5video/dist/styles.css";
@import "video";
@import "layouts";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: "Open Sans", sans-serif;
  background-color: $color-grey-6;
  color: $color-grey;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

/* Change placeholder styles */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $color-grey-4;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: $color-grey-4;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $color-grey-4;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $color-grey-4;
}

input,
textarea {
  &:focus,
  &:active {
    outline: none;
  }
}

/* Change Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  border: 1px solid $color-grey-5;
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-grey;
}

.inpost-link {
  color: $color-dark-blue;
  text-decoration: none;
}
