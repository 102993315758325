@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.category-item {
  @include type-basic;
  margin: 0;
  padding-left: $space-medium;
  color: $color-green;

  &--1 {
    background: {
      image: url("../../../assets/icons/category-machines.svg");
      repeat: no-repeat;
    }
  }

  &--2 {
    background: {
      image: url("../../../assets/icons/category-animals.svg");
      repeat: no-repeat;
    }
  }

  &--3 {
    background: {
      image: url("../../../assets/icons/category-other.svg");
      repeat: no-repeat;
    }
  }
}
