@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.members-list-modal {
  width: 100vw;
  height: 100vh;
  background: $color-white;

  @include bp-large {
    width: 640px;
    height: 360px;
    border-radius: 2px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    height: $space-big;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-5;

    &--left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__header-icon {
    height: 18px;
    margin-left: 11px;
    margin-right: 11px;
  }

  &__header-heading {
    @include type-small;
    margin: 0;
    color: $color-grey-3;
    width: 160px;
  }

  &__member {
    border-bottom: 1px solid $color-grey-5;
    margin-left: 11px;
    margin-right: 11px;
  }

  &__list {
    height: calc(100vh - #{$space-big});
    overflow-y: auto;

    @include bp-large {
      height: 320px;
    }
  }
}
