@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.invite-modal-options {
  width: 250px;
  height: 100%;
  padding: $space-small;
  position: relative;
  background: $color-white;
  border-radius: 10px;

  @media (max-width: 991px) {
    height: 150px;
  }

  .invite-title {
    text-align: left;
    font-weight: bold;
    color: $color-grey-4;
    font-size: 0.95rem;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      height: auto;
    }
  }

  .email-button {
    width: 40px;
    height: 40px;
    margin: $space-small;
    background: {
      image: url("../../../assets/icons/email-button.png");
      repeat: no-repeat;
      size: 40px;
      position: center;
    }
  }

  .SocialMediaShareButton {
    padding: $space-small;
    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}
