@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.notification-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
  padding: 10px;
  border-bottom: 1px solid $color-grey-5;
  background-color: $color-grey-6;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: $color-grey-7;
    cursor: pointer;
  }

  &__active {
    background-color: $color-white;
  }

  .notification-avatar {
    width: 40px;
    text-align: center;
    &__container {
      width: 40px;
    }
  }

  .name-time-ago {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    min-width: 100px;

    a {
      text-decoration: none;
    }

    .user-name {
      font-size: 16px;
      color: $color-green-2;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    .from-now {
      font-size: 12px;
      color: $color-grey-3;
      font-weight: normal !important;
    }
  }

  .action-type {
    font-size: 12px;
    color: $color-black;
    font-weight: normal;

    a {
      text-decoration: none;
      color: $color-dark-blue;
    }
  }

  .notification-comment {
    display: block;
    margin-top: 5px;
    color: $color-grey-4;
  }
}
