@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-activities {
  height: 45px;
  padding: 0 $space-small;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button-content {
    @include type-small;
    padding-left: 28px;
    color: $color-grey;
    font-weight: bold;
    background: no-repeat;

    &--likes {
      background: {
        image: url("../../../assets/icons/likes.svg");
      }
    }

    &--views {
      margin-right: $space-small;
      background: {
        image: url("../../../assets/icons/eye.svg");
      }
    }

    &--comments {
      background: {
        image: url("../../../assets/icons/comments.svg");
      }
    }

    &--shares {
      margin-left: $space-small;
      background: {
        image: url("../../../assets/icons/shares.svg");
      }
    }
  }

  &__left-container {
    white-space: nowrap;
  }

  &__right-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
