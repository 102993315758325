@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.marketplace-sidebar {
  width: 160px;
  position: fixed;
  top: 90px;

  @include bp-large {
    left: calc(50% - 495px);
  }

  @media screen and (min-width: 1050px) {
    left: calc(50% - 510px);
  }

  @include bp-extra-large {
    left: calc(50% - 558px);
  }

  &__button {
    @include type-basic;
    width: 100%;
    height: 30px;
    margin-bottom: $space-small;
    color: $color-white;
    border-radius: 2px;
    padding-left: 50px;
    text-align: left;
    background: {
      color: $color-green;
      repeat: no-repeat;
      size: 24px;
      position: 14px 50%;
    }

    &:active,
    &:hover {
      background-color: darken($color-green, 5%);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--1 {
      background: {
        image: url("../../../assets/icons/category-machines-lightgreen.svg");
      }
    }

    &--2 {
      background: {
        image: url("../../../assets/icons/category-animals-lightgreen.svg");
        size: 28px;
      }
    }

    &--3 {
      background: {
        image: url("../../../assets/icons/category-other-lightgreen.svg");
      }
    }
  }

  &__sell-button {
    margin-bottom: $space-medium;

    .main-button {
      min-height: 40px;
    }
  }

  &__filter-buttons {
    margin-bottom: $space-medium;
  }

  &__vicinity-input {
    margin-bottom: $space-large;
    padding: $space-small;
    border: 1px solid $color-grey-5;
    background: $color-white;
  }
}
