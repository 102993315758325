@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.info-modal {
  width: 100vw;
  max-width: 640px;
  padding: 40px $space-small $space-small;
  background-color: $color-white;

  @include bp-large {
    position: static;
    border-radius: 2px;
    border: 1px solid $color-grey-5;
  }

  &__heading {
    @include type-extra-large;
    margin: 0 0 $space-small;
    font-weight: bold;
    color: $color-grey;
  }

  &__message {
    @include type-large;
    margin: 0 0 $space-medium;
    color: $color-grey-3;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__option-buttons {
    display: flex;

    button {
      padding: 0 $space-small;

      @include bp-large {
        padding: 0 $space-medium;
      }
    }
  }

  &__secondary-button {
    margin-right: $space-small;

    @include bp-large {
      margin-right: $space-large;
    }
  }
}
