@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.image-preview-modal {
  width: 100%;
  height: 100%;
  background: $color-black;

  @include bp-large {
    width: 90vw;
    max-width: 1140px;
    height: 90vh;
    max-height: 800px;
    position: relative;
    border-radius: 2px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
