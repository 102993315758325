@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.end-message {
  display: flex;
  justify-content: center;

  &__text {
    text-align: center;
    @include type-basic;
    color: $color-grey-4;
  }
}
