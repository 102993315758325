@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.comment {
  display: flex;
  position: relative;

  &__avatar {
    margin-right: $space-small;
  }

  &__main-content {
    width: 100%;
    padding: $space-small;
    border-radius: 2px;
    background: $color-grey-6;
  }

  &__author-content {
    display: flex;
    align-items: flex-end;
    margin-bottom: $space-small;
  }

  &__link {
    text-decoration: none;
  }

  &__author {
    @include type-basic;
    margin-right: 10px;
    font-weight: bold;
    color: $color-green-2;
  }

  &__text {
    @include type-basic;
  }

  &__edit-button {
    width: 22px;
    height: 20px;
    position: absolute;
    right: -22px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    background: {
      image: url("../../../assets/icons/submenu-lightgrey.svg");
      repeat: no-repeat;
      size: 22px;
      position: center;
    }
  }

  &__menu {
    width: 160px;
    padding: $space-small;
    position: absolute;
    right: 2px;
    top: 16px;
    z-index: 1;
    border: 1px solid $color-grey-5;
    background: $color-white;
  }

  &__menu-item {
    @include type-small;
    height: 18px;
    padding-left: $space-medium;
    color: $color-grey-2;
    background: {
      repeat: no-repeat;
      position: 0 0;
    }

    &--delete {
      background: {
        image: url("../../../assets/icons/delete-dark-grey.svg");
        size: 12px;
      }
    }
  }
}
