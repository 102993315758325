@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.create-farm-tour-modal {
  width: 90vw;
  //max-width: 1060px;
  height: 100%;
  position: relative;
  background: $color-white;

  .cover-title {
    width: 170px;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 0;
    margin-bottom: 5px;
    font-weight: 300;
    color: $color-grey-3;
    font-size: 14px;
  }

  .modal-close-button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 0;
  }

  .modal-data {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;

    @media (max-width: 991px) {
      height: 85vh;
    }
  }

  .title-wrapper {
    //margin-bottom: 20px;

    .title {
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: $font-middle;
      color: $color-grey;
    }
    .subtitle {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 14px;
      color: $color-grey-2;
    }
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    padding: 0 0 80px;
    overflow-y: scroll;

    @include bp-large {
      padding: 0;
      overflow: hidden;
      display: flex;
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    & > div[data-react-beautiful-dnd-droppable] {
      flex-wrap: wrap;
      @media (max-width: 539px) {
        justify-content: center;
      }
    }

    @media (max-width: 539px) {
      justify-content: center;
    }

    @include bp-large {
      //width: 370px;
      width: 100%;
      height: 82vh;
      padding-bottom: $space-medium;
      overflow-y: auto;
    }
  }

  &__image {
    width: 170px;
    height: 170px;
    margin: 0 $space-medium $space-medium 0;

    @media (max-width: 539px) {
      margin-right: 15px;
      margin-left: 15px;
    }
    display: inline-block;
    position: relative;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    @include bp-large {
      margin-right: $space-medium;
    }
  }

  &__plus-one {
    width: 170px;
    height: 170px;
    //display: inline-block;
    margin: 0;
    font-size: 100px;
    color: $color-grey-5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    margin-right: 30px;

    @media (max-width: 539px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__delete-image {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    background: {
      image: url("../../../assets/icons/delete.svg");
      repeat: no-repeat;
      size: 12px;
      position: center;
    }
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
  }

  &__image-placeholder {
    position: relative;
    width: 170px;
    height: 170px;
    margin-bottom: 30px;
    margin-right: 30px;
    @media (max-width: 539px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-grey-4;
    cursor: pointer;
    position: absolute;
  }

  &__label-text {
    @include type-small;
    padding-top: 20px;
    color: $color-grey-3;
    font-weight: bold;
    background: {
      image: url("../../../assets/icons/image-light-grey.svg");
      repeat: no-repeat;
      size: 20px;
      position: center 0;
    }
  }

  &__error {
    @include type-small;
    width: 100%;
    margin: $space-extra-small 0;
    color: $color-red;

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__loader {
  }
  &__circle-loader-wrapper {
    position: absolute;
    width: 170px;
    height: 170px;
    text-align: center;

    .loader {
      position: absolute;
      top: 65px;
      margin-top: 0;
    }
  }

  .publish-button-wrapper {
    width: 100%;
    padding: 20px;
    position: absolute;
    bottom: 0;
  }
}
