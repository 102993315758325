@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.contact-seller-form-modal {
  width: 100%;
  max-width: 640px;
  padding: $space-medium $space-small;
  position: relative;
  background-color: $color-white;

  &__heading {
    @include type-extra-large;
    margin: 0 0 $space-medium;
    font-weight: bold;
  }

  &__cancel-button {
    position: absolute;
    bottom: $space-medium;
  }
}
