@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.vicinity-input {
  &__description {
    @include type-small;
    margin: 0 0 $space-extra-small;
    font-weight: bold;
  }

  &__distance-container {
    display: flex;
    justify-content: space-between;
    margin-top: $space-extra-small;
  }

  &__distance {
    @include type-small;
    font-weight: bold;
  }

  &__input {
    width: 100%;
    -webkit-appearance: none;
  }

  &__input:focus {
    outline: none;
  }

  &__input::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: $color-green-3;
    border-radius: 2px;
  }

  &__input::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    margin-top: -2px;
    border-radius: 100%;
    background: $color-green-2;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &__input::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: $color-green-3;
    border-radius: 2px;
  }

  &__input::-moz-range-thumb {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: $color-green-2;
    border: none;
    cursor: pointer;
  }

  &__input::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: $color-green-3;
    border-radius: 2px;
  }

  &__input::-ms-thumb {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: $color-green-2;
    cursor: pointer;
  }
}
