@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.newsfeed-view__fixed-toggler + .newsfeed-view {
  @media (max-width: 991px) {
    margin-top: 59px;
  }
}

.newsfeed-view {
  max-width: 640px;

  @media (max-width: 991px) {
    margin-top: 16px;
  }

  @include bp-large {
    width: 640px;
  }

  &__create-post {
    margin-bottom: $space-small;

    @include bp-large {
      margin-bottom: $space-medium;
    }
    &-hidden {
      display: none;
    }
  }

  &__post {
    margin-bottom: $space-small;
  }

  .hashtag {
    margin-top: 0;
  }
}
