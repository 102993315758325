@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.verify-account {
  max-width: 1110px;
  min-height: calc(100vh - 104px);
  margin: 0 auto;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include bp-large {
    height: 675px;
    align-items: center;
    margin-top: $space-large;
    margin-bottom: 10px;
    padding-top: 0;
    min-height: auto;
    background-color: $color-white;
    border: 1px solid $color-grey-5;
  }

  &__wrapper {
    padding: 0 $space-small;
    width: 100%;

    @include bp-large {
      width: 400px;
    }
  }

  &__heading {
    @include type-extra-large;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__sub-heading {
    @include type-large;
    color: $color-grey-3;
    margin-top: 0;
  }

  &__resend-button {
    margin-top: $space-extra-small;
    @include type-basic;
    font-weight: bold;
    color: $color-green-2;

    &.__link {
      display: inline-block;
      text-decoration: none;
    }
  }
  .entered-email-header {
    margin-top: 30px;
    font-weight: bold;
  }

  .wrong-email-message {
    margin-top: $space-big;
    margin-bottom: $space-small;
  }

  .entered-email__email {
    display: inline-block;
    margin-right: $space-small;
  }
}
