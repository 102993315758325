@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.farm {
  min-height: 100%;

  .farm-activities {
    display: flex;
    flex-direction: row;
  }

  @include bp-large {
    padding-top: $space-medium;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__header-image {
    display: none;
  }

  &__header-mobile {
    margin-bottom: $space-small;

    @include bp-large {
      display: none;
    }
  }

  &__header-desktop {
    margin-bottom: $space-big;
  }

  &__sidebar {
    display: none;

    @include bp-large {
      display: block;
    }

    &--left {
      width: 160px;
    }

    &--right {
      width: 160px;
      margin-top: 280px;
      height: 300px;
      border-radius: 2px;
      border: 1px solid $color-grey-5;
      background-color: $color-white;

      @include bp-extra-large {
        width: 255px;
      }
    }
  }

  &__feed {
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: $space-large;

    @include bp-large {
      width: 640px;
      margin: 0 $space-small;
      padding-bottom: 0;
      flex-shrink: 0;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 $space-medium;
    }
  }

  &__registration {
    max-width: 640px;
    margin: 0 auto;
    padding-bottom: $space-large;

    @include bp-large {
      width: 640px;
      margin: 0 $space-small;
      padding-bottom: 0;
      flex-shrink: 0;
    }

    @media screen and (min-width: 1050px) {
      margin: 0 $space-medium;
    }
  }

  &__post {
    margin-bottom: $space-small;
  }

  &__title-image-container {
    display: none;

    @include bp-large {
      width: calc(100% + 175px);
      height: 250px;
      margin-bottom: $space-medium;
      display: block;
      position: relative;
    }

    @media screen and (min-width: 1050px) {
      width: calc(100% + 190px);
    }

    @include bp-extra-large {
      width: calc(100% + 285px);
    }
  }

  &__title-image {
    @include bp-large {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: {
        image: url("../../../assets/icons/image-dark-grey.svg");
        repeat: no-repeat;
        position: center;
        size: 22px;
        color: $color-grey-5;
      }
      outline: none;
    }

    &--active {
      cursor: pointer;
    }
  }

  &__edit-button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    cursor: pointer;
    background: {
      image: url("../../../assets/icons/edit-white.svg");
      repeat: no-repeat;
      size: 16px;
      position: center;
    }
  }

  &__input {
    display: none;
  }

  &__create-post {
    margin-bottom: $space-medium;
  }

  &__empty {
    @include type-basic;
    text-align: center;
    color: $color-grey-4;
  }

  .create-post-modal__container {
    @include bp-large {
      top: 370px;
    }
  }

  &__register {
    padding: $space-extra-small;

    &--mobile {
      height: 100%;
      margin: 0 20px;
      display: flex;
    }

    &--desktop {
      background-color: $color-white;
    }
  }
}
