@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.newsfeed-view {
  &__fixed-toggler {
    position: fixed;
    width: 640px;
    top: 60px;
    z-index: 49;
  }

  .news-description {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;

    @media (max-width: 991px) {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  &__toggle-feeds {
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background: #ffffff;

    @media (min-width: 992px) {
      border: 1px solid #e5e5e5;
      margin-bottom: 30px;
    }

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }

    @media (max-width: 640px) {
      width: 100%;
    }

    .toggle-feed-button {
      @include type-small;
      flex-grow: 1;
      height: 40px;
      text-align: center;
      color: $color-grey;
      border-right: 1px solid $color-grey-5;

      &.news-button {
        position: relative;
      }

      &:hover {
        color: $color-green;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      &.is-active {
        background-color: $color-green;
        color: white;

        &:hover {
          cursor: default;
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__hidden-toggler {
    display: none;
  }
}
