@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.marketplace__search {
  margin-bottom: 30px;

  transition: background 0.3s ease-out, padding 0.3s ease-out,
    border-radius 0.3s ease-out, box-shadow 0.3s ease-out;

  @media (max-width: 991px) {
    margin-bottom: 15px !important;
  }

  &.fixed-marketplace-search {
    position: fixed;
    top: 60px;
    max-width: 640px;
    width: 640px;
    z-index: 49;

    @media (min-width: 992px) {
      background: #fff;
      padding: 10px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 5px 17px -16px #000;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }
}

.marketplace-search {
  border: 1px solid $color-grey-5;
  background: $color-white;

  @include bp-large {
    display: flex;
  }

  &__search {
    width: 100%;

    .input {
      height: 50px;

      @include bp-large {
        height: 40px;
      }
    }

    .input__field {
      height: 50px;
      border: 1px solid $color-white;

      @include bp-large {
        height: 40px;
      }
    }
  }

  &__category {
    @include bp-large {
      width: calc(100% - 270px);
    }

    .select {
      @include bp-large {
        height: 40px;

        &:after {
          top: 17px;
        }
      }
    }

    .select__field {
      @include bp-large {
        height: 40px;
      }
    }
  }

  &__sell-button {
    padding: $space-small;
    background: $color-grey-5;

    @include bp-large {
      display: none;
    }
  }

  &__range {
    padding: $space-small;

    @include bp-large {
      display: none;
    }
  }
}
