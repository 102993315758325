@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.app-sidebar {
  width: 160px;
  padding: $space-small 0;
  background-color: $color-grey-5;
  border-radius: 2px;

  @include bp-extra-large {
    width: 255px;
  }

  &__section {
    padding: $space-small;

    &:last-child {
      border-bottom: none;
    }
  }

  &__title {
    @include type-small;
    margin: 0 0 $space-small;
    font-weight: bold;
  }

  &__description {
    @include type-small;
    margin: 0 0 0;
    line-height: 1.4;
  }

  &__image {
    width: 100%;
    margin-bottom: $space-small;
  }

  &__link {
    @include type-small;
    display: block;
    margin-top: $space-extra-small;
    text-decoration: none;
    font-weight: bold;
    color: $color-green;
    text-align: right;

    &:hover,
    &:focus {
      color: darken($color-green, 5%);
    }
  }
}
