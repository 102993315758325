@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.shared-content {
  padding: 0 $space-small;
  border-left: 2px solid $color-green;

  &__author {
    display: inline-block;
    margin-bottom: $space-extra-small;
    font-weight: bold;
    color: $color-green-2;
  }

  &__time {
    margin-bottom: $space-extra-small;
  }

  &__text {
    @include type-basic;
  }

  &__image-container {
    text-align: center;
    margin-top: $space-small;
  }

  &__article-price {
    @include type-large;
    margin: $space-small 0;
  }

  &__article-title {
    @include type-basic;
    margin: 0 0 $space-small;
    font-weight: bold;
    color: $color-green-2;
  }

  &__article-category {
    margin-bottom: $space-small;
  }
}
