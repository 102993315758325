.close-button {
  width: 40px;
  height: 40px;
  display: block;
  background: {
    image: url("../../../assets/icons/close.svg");
    repeat: no-repeat;
    size: 15px 15px;
    position: center;
  }
}
