@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.marketplace {
  max-width: 640px;

  &.fixed-state {
    margin-top: 100px;

    @media (max-width: 991px) {
      margin-top: 70px;
    }
  }

  @include bp-large {
    width: 640px;
  }

  &__search {
    margin-bottom: $space-medium;
  }

  &__post {
    margin-bottom: $space-small;
  }
}
