@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-header {
  height: 60px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-5;
  position: relative;

  &__avatar {
    margin-right: $space-small;
  }

  &__author {
    @include type-basic;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: bold;
    color: $color-green-2;
  }

  .farm-profile-wrapper {
    margin-left: 35px;
    display: inline-block;

    .post-header__author {
      font-size: 0.75rem;
      font-weight: bold;
      color: $color-grey-3;
    }
  }

  &__farm-profile {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 5px;
    font-size: 0.75rem;
    font-weight: bold;
    color: $color-grey;
    float: left;
    padding-top: 4px;

    @media (max-width: 991px) {
      text-align: center;
      display: block;
      float: none;
      margin-right: 0;
      padding-top: 0;
    }
  }

  &__sold {
    color: $color-grey;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__post-details {
    display: flex;
    align-items: center;
  }

  &__more-button {
    width: 22px;
    height: 20px;
    background: {
      image: url("../../../assets/icons/submenu.svg");
      repeat: no-repeat;
      size: 22px;
      position: center;
    }
  }

  &__menu {
    width: 150px;
    padding: $space-small;
    position: absolute;
    right: $space-small;
    top: 38px;
    z-index: 2;
    border: 1px solid $color-grey-5;
    background: $color-white;
  }

  &__menu-item {
    @include type-small;
    min-height: 18px;
    padding-left: $space-medium;
    color: $color-grey-2;
    background: {
      repeat: no-repeat;
      position: 0 50%;
    }

    &--edit {
      background: {
        image: url("../../../assets/icons/edit-dark-grey.svg");
        size: 15px;
      }
    }

    &--delete {
      margin-top: $space-small;
      background: {
        image: url("../../../assets/icons/delete-dark-grey.svg");
        size: 12px;
      }
    }
  }

  &__author-details {
    display: flex;
  }

  &__address {
    @include type-small;
    margin-right: 10px;
    position: relative;
    font-weight: bold;
    color: $color-grey-3;

    &::after {
      content: "";
      width: 2px;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 6px;
      right: -6px;
      background-color: $color-grey-3;
    }
  }
}
