@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.create-farm-modal {
  width: 100%;
  height: 100%;
  position: relative;
  background: $color-white;

  @include bp-large {
    width: 970px;
    height: 600px;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    padding: $space-small $space-small 80px;
    overflow-y: scroll;

    @include bp-large {
      padding: 0;
      overflow: hidden;
      display: flex;
    }
  }

  &__farm-data {
    @include bp-large {
      width: calc(100% - 340px);
      padding: $space-medium $space-medium 0;
      display: flex;
    }
  }

  &__farm-members--desktop {
    @include bp-large {
      width: 340px;
      padding-top: $space-medium;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .farm-members-wrapper-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__farm-members--mobile {
    text-align: center;
    margin-bottom: 40px;

    @media (min-width: 992px) {
      display: none;
    }

    .create-farm-modal__heading {
      font-size: $font-small;
      color: $color-grey-3;
      margin-bottom: 10px;
    }
  }

  &__heading {
    @include type-extra-large;
    margin: 0;
    font-weight: bold;

    &--mobile {
      margin-bottom: $space-medium;

      @include bp-large {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: block;
        margin: 0 0 20px;
      }
    }

    &--members {
      margin-bottom: 25px;
    }
  }

  &__row {
    margin-bottom: $space-medium;

    &--inline {
      display: flex;
      align-items: center;

      input {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__form-label {
    width: 110px;
    margin: 0;
    font-size: 0.875rem;
    flex-shrink: 0;
    font-weight: bold;
  }

  &__profile-image-container {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 110px;
      height: 110px;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid $color-grey-5;
      border-radius: 100%;
    }

    .loader__load-message {
      font-size: $font-small;
    }
  }

  &__profile-image {
    width: 102px;
    height: 102px;
    position: relative;
    border: 1px solid $color-grey-5;
    border-radius: 100%;
    background: {
      image: url("../../../assets/icons/farm-dark-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 22px;
      color: $color-grey-6;
    }
  }

  &__title-image {
    width: 150px;
    height: 80px;
    border: 1px solid $color-grey-5;
    border-radius: 2px;
    background: {
      image: url("../../../assets/icons/image-dark-grey.svg");
      repeat: no-repeat;
      position: center;
      size: 20px;
      color: $color-grey-6;
    }

    @media (max-width: 991px) {
      margin-top: 15px;
      margin-right: 3px;
    }
  }

  &__label-container {
    margin-bottom: $space-medium;

    @include bp-large {
      width: 150px;
    }

    &--desktop {
      display: none;

      @include bp-large {
        display: inline-flex;
        flex-direction: column;
      }

      .loader__load-message {
        font-size: $font-small;
      }
    }

    &--mobile {
      @include bp-large {
        display: none;
      }
    }
  }

  &__label-inner-container {
    margin-left: $space-small;

    @include bp-large {
      margin: 10px 0 0;
      text-align: center;
    }

    @media (max-width: 991px) {
      margin-left: 0;
    }
  }

  &__label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @include bp-large {
      width: 100%;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
    }
  }

  &__label-text {
    @include type-small;
    font-weight: bold;
    color: $color-grey-3;
  }

  &__input {
    display: none;
  }

  &__labels {
    @include bp-large {
      display: flex;
      flex-direction: column;
      margin-right: $space-large;
    }

    @media (max-width: 991px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__buttons {
    width: 100%;
    padding: 10px $space-small;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-white;

    @include bp-large {
      padding: $space-small $space-medium $space-medium;
    }
  }

  &__form {
    width: 100%;
  }

  &__inputs {
    @include bp-large {
      height: 420px;
      padding-top: 20px;
      overflow-y: scroll;
    }
  }

  &__submit-button-container {
    position: relative;
  }

  &__error {
    @include type-small;
    margin: $space-small 0;
    color: $color-red;

    @include bp-large {
      width: 180px;
    }

    &--submit {
      width: 300px;
      margin: 0;
      position: absolute;
      right: 0;
      top: -12px;
      text-align: right;

      @include bp-large {
        top: auto;
        bottom: -15px;
      }
    }
  }

  &__members-container {
    margin: 0 0 10px;
  }

  &__members {
    @include type-small;
    color: $color-green;
    margin: 0;
  }

  &__members-avatars {
    margin: 10px 0;
    display: flex;
  }

  &__avatar {
    margin-right: $space-extra-small;
  }

  &__requests {
    @include type-small;
    color: $color-grey-3;
  }

  &__invite {
    margin-top: $space-medium;
    margin-bottom: $space-large;

    @include bp-large {
      margin-bottom: 108px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__delete-picture-button {
    margin-top: $space-extra-small;
    @media (max-width: 991px) {
      margin-bottom: 5px;
    }
  }
}

.input__error-message-location {
  font-size: 0.75rem;
  font-weight: normal;
  color: #d20101;
}

.autocomplete-wrapper {
  position: relative;
  .input__label {
    top: -17px;
    left: 0;
    font-size: 12px;
  }

  .autocomplete-dropdown-container {
    border-bottom: 1px solid $color-grey-6;
    border-left: 1px solid $color-grey-6;
    border-right: 1px solid $color-grey-6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .suggestion-item {
      padding: 7px 5px;
      border-bottom: 1px solid $color-grey-6;
      font-size: 14px;
      &:last-child {
        border-bottom: none;
      }

      &.active {
        cursor: pointer;
        background-color: $color-grey-6;
      }
    }
  }
}
