.rh5v-DefaultPlayer_video {
  display: block !important;

  @include bp-large {
    max-width: 100% !important;
  }
}

.rh5v-Overlay_inner {
  width: 75px !important;
  height: 75px !important;
  /*background: {
    image: url("../assets/icons/play.svg") !important;
    repeat: no-repeat !important;
    size: contain !important;
    position: center !important;
    color: transparent !important;
  }*/
  background-color: transparent !important;

  svg {
    display: none !important;
  }
}

.rh5v-DefaultPlayer_controls {
  background-color: transparent !important;
}

.rh5v-PlayPause_component:hover,
.rh5v-Volume_component:hover {
  background-color: transparent !important;
}

.rh5v-Seek_track {
  height: 10px !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.rh5v-Seek_buffer {
  background-color: white !important;
}

.rh5v-Seek_fill {
  background-color: $color-green !important;
}

.rh5v-Volume_slider {
  background-color: transparent !important;
}

.rh5v-Volume_track {
  background-color: $color-white !important;
  width: 10px !important;
  border-radius: 2px !important;
}

.rh5v-Volume_fill {
  background-color: $color-green !important;
}

.rh5v-DefaultPlayer_controls {
  transition: none !important;
}

.rh5v-PlayPause_component {
  width: 34px !important;
  height: 34px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rh5v-PlayPause_button svg {
  display: none !important;
}

[aria-label="Play video"] {
  width: 18px !important;
  height: 18px !important;
  background: {
    image: url("../assets/icons/play-small.svg") !important;
    repeat: no-repeat !important;
    position: center !important;
    color: transparent !important;
  }
}

[aria-label="Pause video"] {
  width: 18px !important;
  height: 18px !important;
  background: {
    image: url("../assets/icons/pause-small.svg") !important;
    repeat: no-repeat !important;
    position: center !important;
    color: transparent !important;
  }
}

.rh5v-Volume_component {
  height: 34px !important;
  width: 34px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  svg {
    display: none !important;
  }
}

.rh5v-Volume_button {
  width: 18px !important;
  height: 18px !important;
  background: {
    image: url("../assets/icons/sound.svg") !important;
    repeat: no-repeat !important;
    position: center !important;
    color: transparent !important;
  }
}

.rh5v-Fullscreen_component {
  height: 34px !important;
  width: 34px !important;
  background-color: transparent !important;

  svg {
    transform: scale(2) !important;
    position: relative !important;
    top: 2px !important;
  }
}
