@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.post-item {
  border-radius: 2px;
  border: 1px solid $color-grey-5;
  background-color: $color-white;

  &__blurred {
    filter: blur(3px);
    -ms-filter: blur(3px);
  }

  .is-sold {
    background: red;
    color: #fff;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 1;
    padding: 5px 18px;
    border-radius: 5px 0 0 5px;
    font-weight: bold;
  }

  &__text {
    @include type-basic;
    padding: 0 $space-small;
    margin-top: $space-small;

    &--article {
      display: none;
      padding-left: 0;
      margin-bottom: $space-small;
    }

    &--margin {
      margin-bottom: $space-small;
    }
  }

  &__shared-content {
    margin-top: $space-medium;
    margin-bottom: $space-small;
    margin-left: $space-small;
    outline: none;
    cursor: pointer;

    @include bp-large {
      margin-left: $space-medium;
    }

    &--linked {
      @include bp-large {
        cursor: pointer;
      }
    }
  }

  &__image-container {
    text-align: center;
    outline: none;
  }

  &__content {
    outline: none;

    &--linked {
      @include bp-large {
        cursor: pointer;
      }
    }
  }

  &__comments {
    display: none;

    @include bp-large {
      display: block;
    }
  }

  &__article-image {
    text-align: center;
    position: relative;
    outline: none;
  }

  &__article-price {
    @include type-basic;
    margin: 0 0 $space-small;

    &--white {
      margin-bottom: 0;
      position: absolute;
      bottom: $space-small;
      left: $space-small;
      font-size: 1.375rem;
      color: $color-white;
      text-shadow: 2px 2px 4px rgba($color-black, 0.4);
    }

    &--grey {
      @include type-large;
    }
  }

  &__article-title {
    @include type-basic;
    margin: 0 0 $space-small;
    font-weight: bold;
    color: $color-green-2;
  }

  &__article {
    position: relative;
    outline: none;
    cursor: pointer;
  }

  &__article-details {
    padding: $space-small $space-small 0;
  }

  .post-farmtour-title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $color-green-1;
    font-size: 18px;
    font-weight: bold;
  }

  .share-buttons__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div:active, div:focus {
      outline: none;
    }

    div {
      transition: opacity 0.3s ease-out;
    }

    div:hover {
      opacity: 0.8;
    }

    &>div:first-child {
      margin-right: 10px;
    }
  }
}

.hidden-scroll {
  display: none;
}

.post-item-wrapper {
  position: relative;
}

.guest-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &__message {
    &:first-child {
      margin-bottom: 10px;
    }

    background-color: $color-red-1;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    text-align: center;
  }
}
